import { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Box, TableCell, TableHead, TableRow } from '@mui/material';

import 'assets/scss/common.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
import scss from "./ToolPDCAASResult.module.scss";
import scssTable from "components/table/TableV2.module.scss"

import paths from 'utils/network/apiPath';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { breadData } from 'components/bread/BreadBarV2';
import { MuiTableCell, TableHeadRow, TableRow2Body,MuiTableContainer } from 'components/table/MuiTableV2';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { Space, checkInvalidSeq, getIndexArray, indexData, isEmptyObjOrArray, isOnlySpacesOrNewlines } from 'utils/general';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import { methods } from './ToolPDCAAS';
import ERadarChart from 'components/chart/ERadarChart';
import chartProp from 'components/chart/chartProp';
import LoadingAnime from 'components/LoadingAnime';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_pdcaas_r?&organism=1&weight=100&method=0
const jsons = spp.tool.pdcaas_result
const texts = {  
  process: [
    'PDCAAS calculation process',
    'DIAAS calculation process',
  ],
  
  //---------------------------------------------------------------------------
  pdcaas: {
    title: [
      'Analytical data',
      'Digsestible quantities in mixture',
    ],
    unit: [
      '', 'g', 'g/100 g', 'mg/g protein',
      '-', 'g', 'mg',
    ],
    tableHeadCell: jsons.pdcaas_items_text,
    tableBodyCell: jsons.pdcaas_items,
    
    total: {
      title: 'Totals',
      tableBodyCell: jsons.pdcaas_total,
    },
    
    amino_acid: {
      title: 'Amino acids: mg/g protein (total for each amino acid/total protein)',
      tableBodyCell: jsons.pdcaas_amino_acid,
    },
    
    avg: {
      title: 'Weighted average digestibility: sum of digestible protein',
      tableBodyCell: jsons.pdcaas_avg,
    },
  },
  pdcaas_age: {
    tableHeadCell: jsons.age_group_text('PDCAAS'),
    tableBodyCell: jsons.age_group,
    years: [
      'Infants (0-5 years)',
      'Preschool children(1-2 years)',
      'Older children and adolescents (4-18 years)',
      'Adults (>18 years)',
    ],
  },
  
  pdcaas2: {
    tableHeadCell: jsons.pdcaas2_table_text,
    tableBodyCell: jsons.pdcaas2_table,
    
    title: {
      sum: 'Mixture Sum',
      who: 'WHO amino acid pattern(3-10 year)',
      aas: 'AAS',
      pdcaas: 'Mixture PDCAAS',
    },
    
    radar: {
      title: 'Amino acid score',
      who: 'WHO pattern',
    }
  },
  
  //---------------------------------------------------------------------------
  diaas: {
    head: [
      'Composition data',
      'True ileal IAA Digestibility',
      'True ileal digestiible IAA content in mixture',
    ],
    unit: [
      '', 'g', 'g/100 g', 'mg/g protein', '-', '-', '-', '-', 'g', 'mg',
    ],
    tableHeadCell: jsons.diaas_items_text,
    tableBodyCell: jsons.diaas_items,
    
    total: {
      title: 'Totals',
      tableBodyCell: jsons.diaas_total,
    },
    
    amino_acid: {
      title: 'Amino acids: mg/g protein (total for each amino acid/total protein)',
      tableBodyCell: jsons.diaas_amino_acid,
    },
  },
  diaas_age: {
    tableHeadCell: jsons.age_group_text('DIAAS'),
    tableBodyCell: jsons.age_group,
    years: [
      'Infants (birth to 6 months)',
      'Children (6 months to 3 years)',
      'Older children, adolescents, adult',
    ],
  },
  
  //---------------------------------------------------------------------------
  age_group: {
    head: [
      '',
      'Reference patterns: mg/g protein',
      'Amino acid score for mixture: \namino acids/g protein per reference pattern',
      '',
    ],
  },
}

class pdcaas2 {
  static init(title = '') {
    let obj = {}
    for (const key of jsons.pdcaas2_table) {
      if( key === "organism")
        obj[key] = title
      else
        obj[key] = 0
    }
    return obj
  }
  
  static set(arr) {
    let obj = {}
    let idx = 0
    // console.log(arr.length);
    if( Array.isArray(arr) ) {
      for (const key of jsons.pdcaas2_table)
        obj[key] = arr[idx++]
    }
    return obj
  }
  
  static getResultArray(obj) {
    if( Object.isObject(obj) ) {
      let arr = [], val
      for( const key of jsons.pdcaas2_result ) {
        val = obj[key]
        arr.push(val ? val : 0)
      }
      return arr
    }
  }
}

const TableHeadAndRow = (props) => (
  <TableHead>
    <TableRow
      style={{
        backgroundColor: 'var(--green-alpha-alpha-20)',
        
      }}
    >
      {props.children}
    </TableRow>
  </TableHead>
)

const TableBodyAndRow = (props) => (
  <TableBody style={{border:'none'}}>
    <TableRow2Body>
      {props.children}
    </TableRow2Body>
  </TableBody>
)

const MuiTableCellMin = (props) => {
  const {children, ...other} = props
  return (
    <TableCell style={{padding: '1px'}} {...other}>
      {children}
    </TableCell>)
}

const MuiTableCell4Head = (props) => {
  const {children, index, cell1stAlignRight, ...other} = props
  let align = "center"
  if( cell1stAlignRight !== undefined && index === 0)
    align = "right"
  return (
    <MuiTableCell align={align}
    //  className={scssTable.header_cell}
    style={{border:'none'}}
    {...other}
    >
      {children}
    </MuiTableCell>
    )
}

const TableCell4HeadTop = (props) => {
  let style = {fontSize: 16, fontFamily:'Quantico'}
  if(props.style !== undefined)
    Object.assign(style, props.style)
  // console.log(_style);
  return (
    <MuiTableCell4Head align="center" style={style} {...props}>
      {props.children}
    </MuiTableCell4Head>)
}

const TableCell4HeadUnit = (props) => {
  let style = {borderTop: '1px solid var(--green-alpha-alpha-16)', padding: '2px', height: '32px'}
  if(props.style !== undefined)
    Object.assign(style, props.style)
  // console.log(_style);
  return (
    <MuiTableCell4Head align="center" style={style} {...props}>
      {props.children}
    </MuiTableCell4Head>)
}

//---------------------------------------------------------------------------
const ToolPDCAASResult2 = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const organism = searchParams.get(paths.params.organism)
  const weight = searchParams.get(paths.params.weight)
  const method = searchParams.get(paths.params.method)
  const arrOrganism = organism ? organism.split(',') : ''
  const arrWeight = weight ? weight.split(',').map((item) => Number(item)) : ''
  // console.log('arrOrganism', arrOrganism);
  // console.log('arrWeight', arrWeight);
  
  let mth = methods.filter(item => item.label === 'PDCAAS')
  // console.log(mth, mth[0], mth[0].method);
  let isPDCAAS, isDIAAS
  if( mth.length > 0 )
    isPDCAAS = (mth[0].method === Number(method))
  mth = methods.filter(item => item.label === 'DIAAS')
  if( mth.length > 0 )
    isDIAAS = (mth[0].method === Number(method))
  if( !isPDCAAS && !isDIAAS) {
    isPDCAAS = true
    isDIAAS = true
  }
  // console.log('isPDCAAS=', isPDCAAS, 'isDIAAS=', isDIAAS);
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  const words = {
    bread: breadData.create('Tools', 'PDCAAS result data output', bread3rd, 'Result'),
  }
  
  //---------------------------------------------------------------------------
  // Radar Chart
  const radarTitle = texts.pdcaas2.radar.title
  const [radarData, setRadarData] = useState([])
  const radarRawSize = 3
  const [radarIndex, setRadarIndex] = useState([])
  // console.log('radarIndex', radarIndex);
  
  //---------------------------------------------------------------------------
  // const [output, setOutput] = useState({})
  // const [pdcaasOutput, setPDCAASOutput] = useState({})
  const [pdcaasItems, setPDCAASItems] = useState([]) //obj array
  const [pdcaasAge, setPDCAASAge] = useState([]) //obj array
  const [pdcaasTotal, setPDCAASTotal] = useState({}) //obj
  const [pdcaasAA, setPDCAASAA] = useState({}) //obj
  const [pdcaasAvg, setPDCAASAvg] = useState({}) //obj
  
  const [pdcaas2Items, setPDCAAS2Items] = useState([]) //obj array
  const [pdcaas2sum, setPDCAAS2Sum] = useState({})
  const [pdcaas2who, setPDCAAS2WHO] = useState({})
  const [pdcaas2aas, setPDCAAS2AAS] = useState({})
  const [pdcaas2pdcaas, setPDCAAS2PDCAAS] = useState({})
  
  
  // const [diaasOutput, setDIAASOutput] = useState({})
  const [diaasItems, setDIAASItems] = useState([]) //obj array
  const [diaasAge, setDIAASAge] = useState([]) //obj array
  const [diaasTotal, setDIAASTotal] = useState({}) //obj
  const [diaasAA, setDIAASAA] = useState({}) //obj
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const input = jsons.input(arrOrganism, arrWeight, method)
    const config = apiConfig.tool.pdcaas_result(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        // setOutput(data)
        // console.log(data)
        
        let items, age, total, aa, avg
        // PDCAAS
        const pdcaas = data[jsons.output.pdcaas]
        // console.log('pdcaas', pdcaas);
        if( pdcaas !== undefined && Object.isObject(pdcaas) && !isEmptyObjOrArray(pdcaas) ) {
          // setPDCAASOutput(pdcaas)
          items = pdcaas[spp.common.output.items]
          setPDCAASItems(items)
          // console.log('pdcaas items', items)
          
          age = pdcaas[jsons.method[0]] //"age_group",
          setPDCAASAge(age)
          // console.log('pdcaas age', age)
          total = pdcaas[jsons.method[1]] //"total",
          setPDCAASTotal(total)
          // console.log('pdcaas total', total)
          aa = pdcaas[jsons.method[2]] //"amino_acid",
          setPDCAASAA(aa)
          // console.log('pdcaas aa', aa)
          avg = {}
          avg[jsons.pdcaas_avg[0]] = pdcaas[jsons.pdcaas_avg[0]]
          setPDCAASAvg(avg)
          // console.log('pdcaas avg', avg)
          
          //---------------------------------------------------------------------------
          // v2
          let items2 = items.map((item) => {
            let trp = (Number(item["trp_digest"])).toFixed(1)
            let thr = (Number(item["thr_digest"])).toFixed(1)
            let lys = (Number(item["lys_digest"])).toFixed(1)
            // console.log(trp, thr, lys);
            return {
            "organism": item["organism"],
            "trp_digest": trp,
            "thr_digest": thr,
            "Iso": 3,
            "Leu": 4,
            "lys_digest": lys,
            'Met_Cys': 6,
            'Phe_Tyr': 7,
            'Val': 8,
            'His': 9,
          }})
          setPDCAAS2Items(items2)
          
          let resSum = [1, 2, 3, 4, 5, 6, 7, 8, 9]
          let sum = pdcaas2.set(
            [texts.pdcaas2.title.sum, 0, ...resSum])
          setPDCAAS2Sum(sum)
          // console.log('sum', sum);
          
          let resWHO = [0.66, 2.5, 3, 6.1, 4.8, 2.3, 4.1, 4, 1.6]
          let maxWHO = Math.ceil(Math.max(...resWHO))
          let who = pdcaas2.set(
            [texts.pdcaas2.title.who, '-', ...resWHO])
          setPDCAAS2WHO(who)
          // console.log('who', who);
          
          let aas = pdcaas2.set(
            [texts.pdcaas2.title.aas, '-', 1.26, 1.04, 1.21, 1.13, 1.10, 1.15, 1.91, 1.12, 1.39])
          setPDCAAS2AAS(aas)
          // console.log('aas', aas);
          
          let mix = {}
          mix["organism"] = texts.pdcaas2.title.pdcaas
          mix["Proportion"] = '-'
          mix["lys_digest"] = pdcaas[jsons.pdcaas_avg[0]]
          setPDCAAS2PDCAAS(mix)
          // console.log('mix', mix);
          
          // Organism
          let radarInd
          let rs = [], data = []
          let res, max, obj
          items2.forEach((item) => {
            res = pdcaas2.getResultArray(item)
            max = Math.ceil(Math.max(...res))
            // console.log('res=', res, 'max=', max);
            if( max < maxWHO )
              max = maxWHO
            radarInd = jsons.pdcaas2_result_text.map((item) => {
              return chartProp.data.radarIndicator(item, max)
            })
            rs = []
            rs.push( chartProp.data.radarSeries(item["organism"], res))
            rs.push( chartProp.data.radarSeries(
              texts.pdcaas2.radar.who, pdcaas2.getResultArray(who)))
            // console.log('rs', rs);
            
            obj = chartProp.data.createRadar(radarTitle, radarInd, rs)
            data.push(obj)
          })
          //Mixture Sum
          res = resSum
          max = Math.ceil(Math.max(...res))
          radarInd = jsons.pdcaas2_result_text.map((item) => {
            return chartProp.data.radarIndicator(item, max)
          })
          rs = []
          rs.push( chartProp.data.radarSeries(sum["organism"], res))
          rs.push( chartProp.data.radarSeries(
            texts.pdcaas2.radar.who, pdcaas2.getResultArray(who)))
          obj = chartProp.data.createRadar(radarTitle, radarInd, rs)
          data.push(obj)
          
          setRadarData(data)
          // console.log('data', data);
          
          let idx = getIndexArray(data.length, radarRawSize)
          setRadarIndex(idx)
        }
        
        //---------------------------------------------------------------------------
        // DIAAS
        const diaas = data[jsons.output.diaas]
        // console.log('diaas', diaas);
        if( diaas !== undefined && Object.isObject(diaas) && !isEmptyObjOrArray(diaas) ) {
          // setDIAASOutput(diaas)
          items = diaas[spp.common.output.items]
          setDIAASItems(items)
          // console.log('diaas items', items)
          age = diaas[jsons.method[0]] //"age_group",
          setDIAASAge(age)
          // console.log('diaas age', age)
          total = diaas[jsons.method[1]] //"total",
          setDIAASTotal(total)
          // console.log('diaas total', total)
          aa = diaas[jsons.method[2]] //"amino_acid",
          setDIAASAA(aa)
          // console.log('diaas aa', aa)
        }
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  const TableBodyCell = (props) => {
    const { tableBodyCell, children, index, isPDCAAS, isDIAAS } = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    // console.log(isPDCAAS, isDIAAS);
    let isAliceLeft = false, isAvg = false
    let isMixPDCAAS = (children === pdcaas2pdcaas)
    if( tableBodyCell === texts.pdcaas.tableBodyCell
      || tableBodyCell === texts.pdcaas_age.tableBodyCell
      || tableBodyCell === texts.diaas.tableBodyCell
      || tableBodyCell === texts.diaas_age.tableBodyCell
      || tableBodyCell === texts.pdcaas2.tableBodyCell
      ) isAliceLeft = true
    if( children === pdcaas2pdcaas
      ) isAliceLeft = true
    if( tableBodyCell === texts.pdcaas.avg.tableBodyCell
      || tableBodyCell === texts.diaas.total.tableBodyCell
      ) isAvg = true
      
    let color = undefined
    if( isMixPDCAAS )
      color = 'var(--green-alpha-alpha-16)'
    if( children === pdcaas2sum )
      color = 'var(--white-white-40)'
    // if( children === pdcaas2who )
    //   color = '#FF917C'
    
    let value, text, align
    return (
      tableBodyCell.map((cell, index2) => {
        value = children[cell]
        text = value
        if( cell === jsons.age_group[0] ) { //'Age group (years)',
          if(isPDCAAS)
            text = texts.pdcaas_age.years[index]
          if(isDIAAS)
            text = texts.diaas_age.years[index]
        }
        if( index2 !== 0 && isAvg && value === undefined )
          text = '-'
        if( value === undefined && tableBodyCell === texts.pdcaas2.tableBodyCell )
          text = '0'
        if( value === undefined && isMixPDCAAS )
          text = ''
        // console.log(index, 'cell=', cell, 'text=', text);
        
        align = "center"
        if(index2 === 0 && isAliceLeft)
          align = "left"
        
        return (
          <MuiTableCell key={index+''+index2+''+cell} align={align} 
            style={color ? {background: color, border:'none'} : {border:'none'}} >
            {text}
          </MuiTableCell>)
      })
  )}
    
  //---------------------------------------------------------------------------
  useEffect(() => {
    apiResult()

    return () => {
    };
  }, []);  
  
  //---------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={words.bread}>
        <div className={scss.main}>
                    
          {/* PDCAAS */}
          {!isPDCAAS ? null
          : <>
              <div className={'Title-Audiowide20px-Regular-White_75'}>{texts.process[0]}</div>
              
              {/* //--------------------------------------------------------------------------- */}
              {/* v2 */}
              {state.isLoading ? <LoadingAnime /> :
                radarIndex.map((value, count) => {
                  return (
                  <div key={count} className={scss.frame_radar}>
                    {radarData.slice(value.start, value.end + 1).map((data, index) => {
                      let idx = value.start + index
                      if(idx === radarData.length-1)
                        return <ERadarChart key={index} className={scss.radar_box}
                          data={data}
                          options={{color: ['#4bbfa4', '#FF917C' ]}} />
                      else
                        return <ERadarChart key={index} className={scss.radar_box} data={data} />
                    })}
                  </div>)
                })
              }
              
              <div className={scss.frame_block}>
                <MuiTableContainer style={{border:'none'}}>
                  {/* text */}
                  <TableHeadAndRow>
                    {texts.pdcaas2.tableHeadCell.map((title, index) => (
                      <MuiTableCell4Head key={index} index={index}>{title}</MuiTableCell4Head>
                    ))}
                  </TableHeadAndRow>
                  {/* value */}
                  <TableBody style={{border:'none'}}>
                    {pdcaas2Items.map((item, index) => (
                      <TableRow2Body key={index}>
                        <TableBodyCell key={index} index={index} tableBodyCell={texts.pdcaas2.tableBodyCell}>
                          {item}
                        </TableBodyCell>
                      </TableRow2Body>
                    ))}
                  </TableBody>
                  
                  {/* Mixture Sum */}
                  <TableBodyAndRow>
                    <TableBodyCell tableBodyCell={texts.pdcaas2.tableBodyCell}>
                      {pdcaas2sum}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  
                  {/* WHO amino acid pattern(3-10 year) */}
                  <TableBodyAndRow>
                    <TableBodyCell tableBodyCell={texts.pdcaas2.tableBodyCell}>
                      {pdcaas2who}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  
                  {/* AAS */}
                  <TableBodyAndRow>
                    <TableBodyCell tableBodyCell={texts.pdcaas2.tableBodyCell}>
                      {pdcaas2aas}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  
                  {/* Mixture PDCAAS */}
                  <TableBodyAndRow>
                    <TableBodyCell tableBodyCell={texts.pdcaas2.tableBodyCell}>
                      {pdcaas2pdcaas}
                    </TableBodyCell>
                  </TableBodyAndRow>
                </MuiTableContainer>
              </div>
              
              <Space />
              {/* //--------------------------------------------------------------------------- */}
              {/* v1 */}
              <div className={scss.frame_block} style={{border:'none'}}>
                <MuiTableContainer style={{border:'none'}}>
                  <TableHeadAndRow>{/* text */}
                    {texts.pdcaas.title.map((item, index) => {
                      let col = [7, 6]
                      return <TableCell4HeadTop key={item} colSpan={col[index]} style={{borderBottom:'1px solid var(--green-alpha-alpha-20)'}} >
                        {item}</TableCell4HeadTop>
                    })}
                  </TableHeadAndRow>
                  <TableHeadRow>
                    {texts.pdcaas.tableHeadCell.map((title, index) => (
                      <MuiTableCell4Head style={{border:'none'}} key={index} index={index}>{title}</MuiTableCell4Head>
                    ))}
                  </TableHeadRow>
                  {/* unit */}
                  <TableHeadRow>
                    {texts.pdcaas.unit.map((title, index) => {
                      let col = [1, 1, 1, 4, 1, 1, 4]
                      return <TableCell4HeadUnit key={index} index={index} colSpan={col[index]}>
                        {title}</TableCell4HeadUnit>
                    })}
                  </TableHeadRow>
                  {/* value */}
                  <TableBody>
                    {pdcaasItems.map((item, index) => (
                      <TableRow2Body key={index}>
                        <TableBodyCell key={index} index={index} tableBodyCell={texts.pdcaas.tableBodyCell}>
                          {item}
                        </TableBodyCell>
                      </TableRow2Body>
                    ))}
                  </TableBody>
                  {/* Totals */}
                  <TableBodyAndRow>
                    <MuiTableCell colSpan={7+1}>{texts.pdcaas.total.title}</MuiTableCell>
                    <TableBodyCell tableBodyCell={texts.pdcaas.total.tableBodyCell}>
                      {pdcaasTotal}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  {/* Amino acids */}
                  <TableBodyAndRow>
                    <MuiTableCell colSpan={7+1}>{texts.pdcaas.amino_acid.title}</MuiTableCell>
                    <TableBodyCell tableBodyCell={texts.pdcaas.amino_acid.tableBodyCell}>
                      {pdcaasAA}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  
                  {/* Weighted average */}
                  <TableBodyAndRow>
                    <MuiTableCell colSpan={7}>{texts.pdcaas.avg.title}</MuiTableCell>
                    <TableBodyCell tableBodyCell={texts.pdcaas.avg.tableBodyCell}>
                      {pdcaasAvg}
                    </TableBodyCell>
                  </TableBodyAndRow>
                </MuiTableContainer>
                
                {/* Age group */}
                <Space />
                <MuiTableContainer>
                  {/* text */}
                  <TableHeadAndRow>
                    {texts.age_group.head.map((item, index) => {
                      let col = [1, 4, 4, 1]
                      return <TableCell4HeadTop key={index} colSpan={col[index]} style={{borderBottom:'1px solid var(--green-alpha-alpha-20)'}}>{item}</TableCell4HeadTop>
                    })}
                  </TableHeadAndRow>
                  <TableHeadRow>
                    {texts.pdcaas_age.tableHeadCell.map((title, index) => (
                      <MuiTableCell4Head style={{border:'none'}} key={index} index={index}>{title}</MuiTableCell4Head>
                    ))}
                  </TableHeadRow>
                  {/* value */}
                  <TableBody  style={{border:'none'}}>
                    {pdcaasAge.map((item, index) => (
                      <TableRow2Body key={index}>
                        <TableBodyCell key={index} index={index} isPDCAAS={true}
                          tableBodyCell={texts.pdcaas_age.tableBodyCell}>
                          {item}
                        </TableBodyCell>
                      </TableRow2Body>
                    ))}
                  </TableBody>
                </MuiTableContainer>
              </div>
            </>}
          
          {/* //--------------------------------------------------------------------------- */}
          {/* DIAAS */}
          {!isDIAAS ? null
          : <>
              {isPDCAAS ? <Box sx={{ mt: 6 }} /> : null}
              <div className={'Title-Audiowide20px-Regular-White_75'}>{texts.process[1]}</div>
              <div className={scss.frame_block} style={{border:'none'}}>
                <MuiTableContainer>
                  <TableHeadAndRow>{/* text */}
                    {texts.diaas.head.map((item, index) => {
                      let col = [7, 5, 4]
                      return <TableCell4HeadTop key={item} colSpan={col[index]}>
                        {item}</TableCell4HeadTop>
                    })}
                  </TableHeadAndRow>
                  <TableHeadRow className="bgc-dark-green">
                    {texts.diaas.tableHeadCell.map((title, index) => (
                      <MuiTableCell4Head key={index} index={index} style={{padding: 4}}>
                        {title}</MuiTableCell4Head>
                    ))}
                  </TableHeadRow>
                  <TableHeadRow className="bgc-dark-green">
                    {texts.diaas.unit.map((title, index) => {
                      let col = [1, 1, 1, 4, 1, 1, 1, 1, 1, 4]
                      return <TableCell4HeadUnit key={index} index={index} colSpan={col[index]}>
                        {title}</TableCell4HeadUnit>
                    })}
                  </TableHeadRow>
                  <TableBody>
                    {diaasItems.map((item, index) => (
                      <TableRow2Body key={index}>
                        <TableBodyCell key={index} index={index} tableBodyCell={texts.diaas.tableBodyCell}>
                          {item}
                        </TableBodyCell>
                      </TableRow2Body>
                    ))}
                  </TableBody>
                  
                  <TableBodyAndRow>
                    <MuiTableCell>{texts.diaas.total.title}</MuiTableCell>
                    <TableBodyCell tableBodyCell={texts.diaas.total.tableBodyCell}>
                      {diaasTotal}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  
                  <TableBodyAndRow>
                    <MuiTableCell colSpan={7+5}>{texts.diaas.amino_acid.title}</MuiTableCell>
                    <TableBodyCell tableBodyCell={texts.diaas.amino_acid.tableBodyCell}>
                      {diaasAA}
                    </TableBodyCell>
                  </TableBodyAndRow>
                  
                </MuiTableContainer>
              
                {/* Age group */}
                <Space />
                <MuiTableContainer>
                  <TableHeadAndRow>
                    {texts.age_group.head.map((item, index) => {
                      let col = [1, 4, 4, 1]
                      return <TableCell4HeadTop key={index} colSpan={col[index]}>{item}</TableCell4HeadTop>
                    })}
                  </TableHeadAndRow>
                  <TableHeadRow className="bgc-dark-green">
                    {texts.diaas_age.tableHeadCell.map((title, index) => (
                      <MuiTableCell4Head key={index} index={index}>{title}</MuiTableCell4Head>
                    ))}
                  </TableHeadRow>
                  <TableBody>
                    {diaasAge.map((item, index) => (
                      <TableRow2Body key={index}>
                        <TableBodyCell key={index} index={index} isDIAAS={true}
                          tableBodyCell={texts.pdcaas_age.tableBodyCell}>
                          {item}
                        </TableBodyCell>
                      </TableRow2Body>
                    ))}
                  </TableBody>
                </MuiTableContainer>
              </div>
            </>}
          
        </div>
    </LayoutPageV2>
  )
}

export default ToolPDCAASResult2;