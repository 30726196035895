import React from 'react';
// import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';

import v2 from 'assets/scss/_variablesV2.scss';
//---------------------------------------------------------------------------
const NoneTextField = styled((props) => 
  <TextField
    variant="outlined"
    fullWidth
  {...props} />)({
    '& .MuiInputBase-input': {
      fontFamily: 'Quantico',
    },
    '& .MuiInputLabel-root': {
      fontFamily: 'Quantico',
    },
  //for variant='standard'
  '& .MuiInput-underline:after': {
    // borderBottomColor: '#f0f !important',
    // Green Alpha/Alpha_16
    // borderBottom: '1px solid rgba(100, 255, 218, 0.16)',
  },
  //for variant='filled'
  "& .MuiFilledInput-underline:after": {
    // borderBottomColor: '#0ff !important',
    // Green Alpha/Alpha_16
    // borderBottom: '1px solid rgba(100, 255, 218, 0.16)',
  },
  //for variant='outlined'(default)
  '& label.Mui-focused': {
    color: '#ff0 !important',
    // color: v.darkGreen,
    // White/White_50
    color: 'rgba(255, 255, 255, 0.5)',
  },
  '& .MuiOutlinedInput-root': {
    // background: '#f0f',
    // White/White_75
    color: 'rgba(255, 255, 255, 0.75)',
    border: '0px',
    
    '& fieldset': { //select
      // background: '#f00 !important',
      border: '0px',
    },
    '&:hover fieldset': { //hover
      // background: '#0f0 !important',
      border: '0px',
    },
    '&.Mui-focused fieldset': { //focused
      // background: '#00f !important',
      color: 'rgba(255, 255, 255, 1)',
    },
    '&.Mui-disabled fieldset': {
      // background: '#0ff !important',
      // color: 'rgba(255, 255, 255, 0.5)', //not work //todo
    },
  },
});

export default NoneTextField;
