import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';

import 'assets/scss/commonV2.scss';
import scssPeptide from 'views/peptide/PeptideDFBPIDResult.module.scss';
// import scssFull from 'views/peptide/PeptideDFBPIDFull.module.scss';

import { breadData } from 'components/bread/BreadBarV2';
import { cardData } from 'components/bread/BreadCard';
import LoadingAnime from 'components/LoadingAnime';
import Text, { Code, TagText, TagTextWithScrollBar } from 'components/tag/Text';
import { A } from 'components/tag/A';
import NoneButton from 'components/button/NoneButton';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTab from 'components/mui/StyledTab';
import StyledTabs from 'components/mui/StyledTabs';
import cookie from 'utils/cookie';
import { isEmptyObjOrArray, outside } from 'utils/general';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import links from 'utils/links';
import MuiTabPanel, { handleChangeTab, scrollTab } from 'components/mui/MuiTabPanel';
import { RouterLink } from 'components/router/RouterLink';
import { getClassName, getClassNameTitle, getScssNameCell } from 'components/peptide/sppID';
import { handleScroll } from 'components/peptide/sppLayout';
import LayoutPage2V2 from 'layouts/LayoutPage2V2';
import ResultSidebar from 'components/protein/ResultSidebar';

//---------------------------------------------------------------------------
// http://localhost:3000/peptide_list_hotspot?proteinID=SPPPR7720&peptideID=SPPRENI0006&fragment=LDD&peptides=SPPRENI0006
// Go in: Protein > 'Bovine Milk proteins' > choose SPP ID 'SPPPR8484'
// > 'Peptide List' table > 'Functional classification' > 'HotSpot Search'
// path: paths.spp.peptide.hotspot_result(),
const jsons = spp.protein.search.hotspot_result
const texts = {
  bread: breadData.create('Tools', 'HotSpot Search Results for Peptide and Precursor Protein'),
  
  tabs: [
    'Bioactive peptide',
    'Precursor protein',
    'Bioactive peptide coding site',
  ],
}

const tabs = {
  _0peptide: {
    title: texts.tabs[0],
    head: jsons.output_text.slice(0, 3),
    json: jsons.output.slice(0, 3),
  },
  
  _1protein: {
    title: texts.tabs[1],
    head: jsons.output_text.slice(3, 8),
    json: jsons.output.slice(3, 8),
  },
  
  _2sequence: {
    title: texts.tabs[2],
    head: jsons.output_text.slice(8, 11),
    json: jsons.output.slice(8, 11),
  },
}

//---------------------------------------------------------------------------
const PeptideListHotspotResult = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const proteinId = searchParams.get(paths.params.proteinID)
  const peptideID = searchParams.get(paths.params.peptideID)
  const fragment = searchParams.get(paths.params.fragment)
  let peptides = searchParams.get(paths.params.peptides)
  peptides = peptides.split(",");

  let input = jsons.input(peptideID, proteinId, fragment)
  
  //---------------------------------------------------------------------------
  const [output, setOutput] = useState({}) //all of output
  const [outputObj, setOutputObj] = useState({}) //object
  const [card, setCard] = useState([])
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const eventResult = () => {
    const config = apiConfig.tool.hotspot_highlight(input)
    // console.log('input',input);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())

        let output = result.data
        setOutput(output)
        // console.log('output', output)
        
        let card = []
        setCard(card)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  useEffect(() => {
    if (axiosState.keepRest(state))
      eventResult()
  }, [])
  
  //---------------------------------------------------------------------------
  // TabPanel cell
  const TabCell = (props) => { //show table key&value
    const { tab, output, outputObj } = props
    let indexRef = 0 //bug D number always being from 1
    return (
      <>
        <Text className={'Title-Audiowide28px-Regular-Title'}>{tab.title}</Text>
        {tab.head.map((item, index) => {
          // console.log(index, item)
          const json = tab.json[index]
          let isHide = false
          let link = ""
          let isFullCell = (json === "Protein sequence")

          return (isHide
            ? null
            : <div key={index} className={scssPeptide[getScssNameCell(json, tab, index, item)]}>
              {isFullCell
                ? getValue(output, outputObj, tab.json, index, indexRef)
                : <>
                  <div key={`div-${item}`} className={scssPeptide.cell_label}>
                    <Text key={`text-${item}`} className={getClassNameTitle(item)}>
                      {link !== ""
                        ? <A href={link} className={getClassNameTitle(item)} >
                          <TagText html={item} />
                        </A>
                        : <TagText html={item} />}
                    </Text>
                  </div>
                  <div key={`div-${index}`} className={scssPeptide.cell_value}>
                    <Text key={`text-${index}`} className={getClassName(json, output)}>
                      {getValue(output, outputObj, tab.json, index, indexRef)}
                    </Text>
                  </div>
                </>}
            </div>)
        })}
      </>
    )
  }
  
  function getValue(output, outputObj, json, index, indexRef) {
    // console.log("output:", output)
    // console.log('json:', json)
    // console.log('index:', index)
    // console.log('index', index)
    let idx = index
    const name = json[idx] //db name
    // console.log('name', name)
    let valueReturn = name
    if (name in output) {
      const value = output[name] //db value
      // console.log('name=', name, 'value=', value)
      valueReturn = value

      // console.log(name);
      switch (name) { //spp.tool.hot_spot_result.output
        case "Protein sequence":
          return (<Code>{value.toCode()}</Code>)
        // case 'Database references':
        //   return links.outside.UniprotKB(value)
        case 'Peptide_SPP_ID':
          let cls = output["Peptide activity"]
          return (
            <RouterLink newTab to={paths.spp.peptide.id_result(value, cls)}>
              <TagText html={value} />
            </RouterLink>)
        case 'Protein_SPP_ID':
          return (
            <RouterLink newTab to={paths.spp.protein.id_result(value)}>
              <TagText html={value} />
            </RouterLink>)
        default:
          break
      }
    } else {
      // console.log(name);
      valueReturn = name
    }
    return (<TagText html={valueReturn} />)
  }
  
  //---------------------------------------------------------------------------
  // TabPanel
  const isShownAllTabs = true //false=multi tabs
  const ref = useRef([])
  const [tabValue, setTabValue] = React.useState(0);

  /********************************************
 * Initialize activeTab & Listen scroll - Start
 ********************************************/
  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(ref, setTabValue));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
 
  const ContentObjList = [
    <TabCell tab={tabs._0peptide} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._1protein} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._2sequence} output={output} outputObj={outputObj}></TabCell>,
  ]
  
  //---------------------------------------------------------------------------
  return (
    <LayoutPage2V2 bread={texts.bread} card={card} output={output}>
      <div className={scssPeptide.layout}>
        <ResultSidebar className={scssPeptide.frame_left}>
          <StyledTabs value={tabValue} style={{ padding:'20px'}}
            onChange={(e, value) => state.isLoading ? null : handleChangeTab(e, value, setTabValue, isShownAllTabs, ref) }>
            {texts.tabs.map((item, index) => (
              <StyledTab key={index} index={index} label={item}/>
            ))}
          </StyledTabs>
        </ResultSidebar>
        {isShownAllTabs && state.isLoading ? <LoadingAnime /> :
          <div className={scssPeptide.frame_right}>
            {ContentObjList.map((item, index) => (
              <MuiTabPanel key={index} index={index} value={tabValue} tabRef={ref}
                isShownAllTabs={isShownAllTabs}>
                {!isShownAllTabs && state.isLoading ? <LoadingAnime /> : item}
              </MuiTabPanel>
            ))}
          </div>
        }
      </div>
    </LayoutPage2V2>
  )
}
export default PeptideListHotspotResult