import BarChartIcon from '@mui/icons-material/BarChart';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { IconButton, TableBody, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { color } from 'echarts';
import { BorderRight, Height } from '@mui/icons-material';
import { height } from '@amcharts/amcharts4/.internal/core/utils/Utils';
// import Chart from "chart.js/auto";

import 'assets/scss/common.scss';
import 'assets/scss/commonV2.scss';
// import v from 'assets/scss/_variables.scss';
import scssPeptide from 'views/peptide/PeptideDFBPIDResult.module.scss';
import scssTable from 'components/table/TableV2.module.scss'

import { breadData } from 'components/bread/BreadBarV2';
import { cardData } from 'components/bread/BreadCard';
import LoadingAnime from 'components/LoadingAnime';
import Text, { TagText, Code } from 'components/tag/Text';
import { MuiTableContainer, TableRow2Body } from 'components/table/MuiTableV2';
import { A } from 'components/tag/A';
import NoneButton from 'components/button/NoneButton';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import StyledTab from 'components/mui/StyledTab';
import StyledTabs from 'components/mui/StyledTabs';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { Space, getFullPath, navigateNewTab } from 'utils/general';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import 'utils/string';
import links, { linkData } from 'utils/links';
import MuiTabPanel, { handleChangeTab, scrollTab } from 'components/mui/MuiTabPanel';
import { RouterLink } from 'components/router/RouterLink';
import { getClassName, getClassNameTitle, getScssNameCell } from 'components/peptide/sppID';
import { setEHPToolState } from 'views/tool/ToolEHPToolSearch';
import { handleScroll } from 'components/peptide/sppLayout';
import LayoutPage2V2 from 'layouts/LayoutPage2V2';
import ResultSidebar from 'components/protein/ResultSidebar';

//---------------------------------------------------------------------------
// http://localhost:3000/protein_id?id=SPPPR7720
// Go in: 'Protein' page -> any card button -> any 'SPP ID' -> this page
// path: paths.spp.protein.id_result(),
const jsons = spp.protein.search.id
const texts = {
  tabs: [
    'Overview',
    'Protein Sequence',
    'Peptide List',
  ],

  chart_sequence: {
    title: 'Cumulative Fragments',
    //'Sum: 370 | Peptide sequences: 183'
    text: 'Sum:',
    text2: 'Peptide sequences:',
    x: '',
    y: 'Sequence count',
  },

  chart_fragment: {
    title: 'Unique Sequences',
    // 'Sum: 907 | Cumulative peptide sequences: 495'
    text: 'Sum:',
    text2: 'Cumulative peptide sequences:',
    x: '',
    y: 'Fragment count',
  },
}

const tabs = {
  _00card: {
    head: jsons.output_text.slice(0, 3 + 1),
    json: jsons.output.slice(0, 3 + 1),
  },

  _0overview: {
    title: texts.tabs[0],
    head: [
      ...jsons.output_text.slice(4, 5 + 1),
      ...jsons.output_text.slice(1, 1 + 1),
      'Enzymatic Hydrolysis Prediction',
      jsons.output_text[9], // 'UniProt',
    ],
    json: [
      ...jsons.output.slice(4, 5 + 1),
      ...jsons.output.slice(1, 1 + 1),
      'SPP---Enzymatic Hydrolysis Prediction Tool (EHP-Tool)',
      jsons.output[9],
    ],
  },

  _1protein: {
    title: texts.tabs[1],
    head: [
      ...jsons.output_text.slice(6, 7 + 1),
    ],
    json: [
      ...jsons.output.slice(6, 7 + 1),
    ],
  },

  _2peptide: {
    title: texts.tabs[2],
    text: 'Sequence coding analysis of bioactive peptides encoded in Transforming growth factor beta-2 proprotein.',
    button_list: [
      <FormatListBulletedIcon />,
      <BarChartIcon />,
    ],

    list: {
      head: jsons.items_text,
      cell: jsons.items,
      text: [
        'Sum',
        'Bioacitive peptide sequences',
        '',
        '(Contains repetitive fragments)',
      ],
      no_repet: function (number) {
        return (<>
          {number}
          <span style={{ color: 'var(--white-white-50)', fontSize: '12px' }}>
            {'\n(No repetitive fragments)'}
          </span>
        </>)
      },
      contains_repet: function (number) {
        return (<>
          {number}
          <span style={{ color: 'var(--white-white-50)', fontSize: '12px' }}>
            {'\n(Contains repetitive fragments)'}
          </span>
        </>)
      },
    },
  },
}

const MuiButton = (props) => {
  return (
    <NoneButton className={scssPeptide.cell_button} variant="contained" {...props}>
      <Text className='b2strong-darkGreen'>{props.children}</Text>
    </NoneButton>
  )
}

const MuiTableCell = (props) => (
  <StyledTableCell style={{ whiteSpace: 'pre-line', border:'none' }} {...props}>
    {props.children}
  </StyledTableCell>
)

const MuiTableHeadCell = (props) => (
  <MuiTableCell className={scssTable.header_cell} style={{height:'50px',verticalAlign: 'middle', borderRight:'1px solid var(--green-alpha-alpha-16)'}}
    {...props}>
    {props.children}
  </MuiTableCell>
)

const MuiTableCell1st = (props) => (
  <MuiTableCell component="th" scope="row"
    {...props}>
    {props.children}
  </MuiTableCell>
)

const MuiTableHeadCell1st = (props) => (
  <StyledTableCell style={{ width: 190 }} align='right'
    component="th" scope="row" className="bgc-grey50" {...props}>
    {props.children}
  </StyledTableCell>
)

const TableBodyCell = ({ navigate, ...props }) => {
  // console.log('props', props);
  // console.log('tableBodyCell', props.tableBodyCell);
  // classification: "ace_inhibitory_peptides", cumulative_fragments: 255, unique_sequences: 134
  // console.log('children', props.children);
  // let link = paths.spp.protein.peptide_list()
  let isLink = (props.children["unique_sequences"] !== 0)

  return (
    props.tableBodyCell.map((cell, index) => {
      // to={paths.spp.tool.aasdResourse[index]}
      let name = `${props.children[cell]} peptides`
      return <MuiTableCell key={index} style={{height:'60px'}}>
        {index > 0
        ? props.children[cell]
        : isLink
          ? <u className={scssTable.body_cell_textLink}>{name}</u>
          : name}
      </MuiTableCell>
    }))
}

const rowChildren3 = (props, className = null) => {
  return (
    <TableRow2Body className={className} style={{}}>
      {props.map((item, index) => (
        index === 0
          ? <MuiTableCell1st   key={index} colSpan={2}>{item}</MuiTableCell1st>
          : <MuiTableCell style={{color:'var(--white-white)',fontWeight:'700'}} key={index} align={'left'}>{item}</MuiTableCell>
      ))}
    </TableRow2Body>
  )
}

const rowChildren = (row, index, type) => {
  return (
    <TableRow key={row.name}>
      <MuiTableHeadCell1st>{row.name}</MuiTableHeadCell1st>

      <MuiTableCell> {type === "uni_port" ?
        links.outside.UniprotKB(row.value)
        : type === "ehp_link"
          ? <RouterLink newTab to={paths.spp.tool.ehp_tool_search()}>
              <TagText html={row.value} />
            </RouterLink>
          : <TagText html={row.value} />
      }</MuiTableCell>
    </TableRow>
  )
}

// Chart.register(CategoryScale);
// Chart.defaults.font.size = 16;
//---------------------------------------------------------------------------
const ProteinDFBPIDResult = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const indexOfDFBPID = searchParams.get(paths.params.id)
  let input = jsons.input(indexOfDFBPID) //13
  // console.log("input:",input);
  
  let location = useLocation()
  let bread = linkData(indexOfDFBPID, getFullPath(location))
  let json4th = JSON.stringify(bread)
  // console.log(bread, json4th);
  cookie.setCookie(cookie.keys.protein.bread4th, json4th)
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('cookie bread3rd:', bread3rd);
  const words = {
    bread: breadData.create('Protein', '', bread3rd, indexOfDFBPID),
  }
  
  //---------------------------------------------------------------------------
  const [output, setOutput] = useState({}) //all of output
  const [outputObj, setOutputObj] = useState({}) //object
  const [card, setCard] = useState([])
  const [list, setList] = useState({}) //peptide_list data
  const [items, setItems] = useState([]) //items data in peptide_list
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  const apiResult = () => {
    const config = apiConfig.protein.id_search(input) //13
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        let output = result.data
        setOutput(output)
        // console.log('output', output)

        let list = output[jsons.output[8]] //'peptide_list'
        // console.log(list);
        if (list === 'null' || list === 'undefined') {
          jsons.peptide_list.forEach((item, index) => {
            // console.log(item);
            if (index === 0)
              list[item] = [] //'items'
            else
              list[item] = 0
          })
        }
        setList(list)
        // console.log('peptide_list', list);

        // console.log(jsons.peptide_list[0]);
        let items = list[jsons.peptide_list[0]] //'items'
        setItems(items)
        // console.log('items', items);

        setPeptideDisplayNameList(items.map(item => `${item.display_name} peptides`))
        
        // let obj = {} //object in output
        // setOutputObj(obj)
        // console.log(obj)

        let card = []
        for (const i of (Array(4).keys())) {
          card.push(cardData(jsons.output_text[i], output[jsons.output[i]],))
        }
        setCard(card)
        // console.log(card);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    ref.current = ref.current.slice(0, texts.tabs.length)
    if (axiosState.keepRest(state))
      apiResult()
  }, [items])
  
  //---------------------------------------------------------------------------
  // TabPanel cell
  const TabCell = (props) => { //show table key&value
    const { tab, output, outputObj } = props
    let indexRef = 0 //bug D number always being from 1
    return (
      <>
        <Text className={'Title-Audiowide28px-Regular-Title'}>{tab.title}</Text>
        {tab.head.map((item, index) => {
          // console.log(index, item)
          const json = tab.json[index]
          let isHide = false
          let link = ""
          let isFullCell = (item === 'Protein sequence')
          return (isHide
            ? null
            : 
            <div key={index} className={scssPeptide[getScssNameCell(json, tab, index)]}>
              {isFullCell
                ? getValue(output, outputObj, tab.json, index, indexRef)
                : <>
                  <div key={`div-${item}`} className={scssPeptide.cell_label}>
                    <Text key={`text-${item}`} className={getClassNameTitle(item)}>
                      {link !== ""
                        ? <A href={link} className={getClassNameTitle(item)} >
                          <TagText html={item} />
                        </A>
                        : <TagText html={item} />}
                    </Text>
                  </div>
                  <div key={`div-${index}`} className={scssPeptide.cell_value}>
                    <Text key={`text-${index}`} className={getClassName(json, output)}>
                      {getValue(output, outputObj, tab.json, index, indexRef)}
                    </Text>
                  </div> 
                </>}
            </div>
            )
        })}
      </>
    )
  }
  
  function getValue(output, outputObj, json, index, indexRef) {
    // console.log('index', index)
    let idx = index
    const name = json[idx] //db name
    // console.log('name', name)
    let valueReturn = name
    if (name in output) {
      const value = output[name] //db value
      // console.log('name=', name, 'value=', value)

      if (Array.isArray(value)) {
        // console.log('name', name, 'value', value)
        let string = ''
        switch (name) { //spp.protein.search.id.output
          case "primary_literature":
            valueReturn = string
            break
          default:
            valueReturn = name
        }
      }

      if (Object.isObject(value)) {
        // console.log('name', name, 'value', value)
        let string = ''
        switch (name) { //spp.protein.search.id.output
          case "peptide_mass":
            string = ''
            for (const i of Array(jsons.peptide_mass_text.length).keys()) {
              const txt = jsons.peptide_mass_text[i]
              const label = jsons.peptide_mass[i]
              const val = outputObj.peptide_mass[label]
              string += `${txt}${val}\n`
            }
            valueReturn = string
            break
          default:
            valueReturn = name
        }
      }
      
      if(value === null) {
        valueReturn = ''
      } else {
        if(!Array.isArray(value) && !Object.isObject(value)) {
          // console.log(name);
          switch (name) { //spp.protein.search.id.output
            case "uni_prot":
              return links.outside.UniprotKB(value)
            case "protein_sequence":
              return (<Code>{value.toCode()}</Code>)
            default:
              valueReturn = value
          }
        }
      }
    } else {
      // console.log(name);
      switch (name) { //tabs.xxx.json
        case 'SPP---Enzymatic Hydrolysis Prediction Tool (EHP-Tool)':
          return (
              <RouterLink newTab to={paths.spp.tool.ehp_tool_search()}
                onClick={() => handleClickEHPtool(output)}>
                <TagText html={name} />
              </RouterLink>)
        default:
          valueReturn = name
      }
    }
    return (<TagText html={valueReturn} />)
  }
  
  function handleClickEHPtool(output) {
    let ps = output["protein_sequence"]
    // console.log('protein_sequence', ps);
    setEHPToolState(ps)
  }
  
  //---------------------------------------------------------------------------
  // TabPanel cell from table  
  const TabCellTable = (props) => { //no table key&value just show table
    const { tab, output } = props
    return (
      <div>
        <div className={scssPeptide.right_cell_title}>
          <Text className={'Title-Audiowide28px-Regular-Title'}>{tab.title}</Text>
          <div className={scssPeptide.title_buttons}>
            {tabs._2peptide.button_list.map((item, index) => {
              return <IconButton
                key={index}
                className={selectedChartType === index ? scssPeptide.title_button_select : scssPeptide.title_button}
                onClick={() => {
                  switch (index) {
                    case 0:
                      setSelectedChartType(TABLE_LIST)
                      break;
                    default:
                      setSelectedChartType(BAR_CHART)
                      break;
                  }
                }}
              >{item}</IconButton>
            }
            )}
          </div>
        </div>

        <div className={scssPeptide.right_cell_last}>
          <Text style={{color:'var(--white-white-75)'}}>
            <TagText html={tab.text} />
          </Text>
        </div>

        <div>
          {selectedChartType === TABLE_LIST&&
            <MuiTableCell className={scssPeptide.cell_table}>
              <MuiTableContainer size='small'>
                {tabs._2peptide.list.head.map((item, index) => (
                  <MuiTableHeadCell key={index}>{item}</MuiTableHeadCell>
                ))}
                <TableBody style={{border:'none'}}>
                  {/* Classification list */}
                  {items.map((item, index) => {
                    let isLink = (item["unique_sequences"] !== 0)
                    return (
                    <TableRow2Body key={index} className={scssTable.body_row_interactive}
                      onClick={() => isLink ? handleClickPeptide(indexOfDFBPID, item) : null}
                      style={{ cursor: isLink ? "pointer" : "auto" , borderBottom:'1px solid var(--green-alpha-alpha-16)'}}>
                      <MuiTableCell component="th" scope="row">{index + 1}</MuiTableCell>
                      <TableBodyCell tableBodyCell={tabs._2peptide.list.cell} navigate={navigate}>{item}</TableBodyCell>
                    </TableRow2Body>)
                  })}
                  
                  {/* Sum */}
                  {rowChildren3([tabs._2peptide.list.text[0], list[jsons.peptide_list[1]], list[jsons.peptide_list[3]]]
                    , scssPeptide.table_sum)}
                    
                  {/* Bioacitive peptide sequences */}
                  {rowChildren3([
                    tabs._2peptide.list.text[1],
                    tabs._2peptide.list.no_repet(list[jsons.peptide_list[2]]),
                    tabs._2peptide.list.contains_repet(list[jsons.peptide_list[4]])
                  ])}
                </TableBody>
              </MuiTableContainer>
            </MuiTableCell>}

          {selectedChartType === BAR_CHART &&
            <>
              <ChartSection
                chartBlockTitle={texts.chart_sequence.title}
                chartBlockSubTitle={texts.chart_sequence.text}
                chartBlockSubTitleNumber={list["unique_sequences_sum"]}
                chartBlockSubTitle2={texts.chart_sequence.text2}
                chartBlockSubTitleNumber2={list["unique_sequences_total"]}
                mainChart={
                  <BarChart
                    data={chartSequenceData}
                    options={chartSequenceOptions}
                    showTableLabel={false}
                    yAxisLabel={texts.chart_sequence.y}
                  />
                } />

              <Space />
              <ChartSection
                chartBlockTitle={texts.chart_fragment.title}
                chartBlockSubTitle={texts.chart_fragment.text}
                chartBlockSubTitleNumber={list["cumulative_fragments_sum"]}
                chartBlockSubTitle2={texts.chart_fragment.text2}
                chartBlockSubTitleNumber2={list["cumulative_fragments_total"]}
                mainChart={
                  <BarChart
                    data={chartFragmentData}
                    options={chartFragmentOptions}
                    showTableLabel={false}
                    yAxisLabel={texts.chart_fragment.y}
                  />
                } />
            </>
          }
        </div>
      </div>
    )
  }

  const handleClickPeptide = (indexOfDFBPID, children) => {
    cookie.setCookie(cookie.keys.peptide.selectedPeptideClsTableName, children["classification"])
    cookie.setCookie(cookie.keys.peptide.selectedPeptideClsName, children["display_name"])
    // navigate(`${link}?id=${indexOfDFBPID}&class=${children["classification"]}`)
    navigate(paths.spp.protein.peptide_list(indexOfDFBPID, children["classification"]))
  }
  
  //---------------------------------------------------------------------------
  // Chart
  const TABLE_LIST = 0, BAR_CHART = 1;
  const [selectedChartType, setSelectedChartType] = useState(TABLE_LIST)
  const [peptideDisplayNameList, setPeptideDisplayNameList] = useState([])

  const chartSequenceOptions = chartProp.options.axisTitleXY(texts.chart_sequence.x, texts.chart_sequence.y)
  const chartSequenceData = chartProp.data.carate(
    '',
    '',
    '',
    peptideDisplayNameList,
    items.map((data) => data[jsons.items[1]]),
  )

  const chartFragmentOptions = chartProp.options.axisTitleXY(texts.chart_fragment.x, texts.chart_fragment.y)
  const chartFragmentData = chartProp.data.carate(
    '',
    '',
    '',
    peptideDisplayNameList,
    items.map((data) => data[jsons.items[2]]),
  )
  
  //---------------------------------------------------------------------------
  // TabPanel
  const isShownAllTabs = true //false=multi tabs
  const ref = useRef([])
  const [tabValue, setTabValue] = React.useState(0);
  
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(ref, setTabValue), true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
 
  const ContentObjList = [
    <TabCell tab={tabs._0overview} output={output} outputObj={outputObj}></TabCell>,
    <TabCell tab={tabs._1protein} output={output} outputObj={outputObj}></TabCell>,
    <TabCellTable tab={tabs._2peptide} output={output} outputObj={outputObj}></TabCellTable>
  ]

  return (
    <LayoutPage2V2 bread={words.bread} card={card} output={output}>
      <div className={scssPeptide.layout}>
        <ResultSidebar className={scssPeptide.frame_left}>
          <StyledTabs value={tabValue} style={{padding:'20px'}}
            onChange={(e, value) => state.isLoading ? null : handleChangeTab(e, value, setTabValue, isShownAllTabs, ref) }>
            {texts.tabs.map((item, index) => (
              <StyledTab key={index} label={item} index={index} />
            ))}
          </StyledTabs>
        </ResultSidebar>
        {isShownAllTabs && state.isLoading ? <LoadingAnime /> :
          <div className={scssPeptide.frame_right}>
            {ContentObjList.map((item, index) => (
              <MuiTabPanel key={index} index={index} value={tabValue} tabRef={ref}
                isShownAllTabs={isShownAllTabs}>
                {!isShownAllTabs && state.isLoading ? <LoadingAnime /> : item}
              </MuiTabPanel>
            ))}
          </div>
        }
      </div>
    </LayoutPage2V2>
  )
}

export default ProteinDFBPIDResult;