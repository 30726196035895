import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';

import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import v from 'assets/scss/_variables.scss';
import v2 from 'assets/scss/_variablesV2.scss';
//------------------------------------------------------------------------------
const MuiTooltip = styled(({ className, ...props }) => (
  <Tooltip placement="bottom" arrow classes={{ popper: className }} {...props} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: v.green,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: v.green,
    color: v.darkGreen,
    fontSize: '16px',
  },
}));

export default MuiTooltip;