import React from 'react'
import { Link } from 'react-router-dom';
import { Card, CardActionArea, CardMedia, Grid } from '@mui/material';

import scssItem from 'views/product/Products.module.scss'
import { breadData } from 'components/bread/BreadBarV2';
// https://mall.amway.com.cn/productDetail?skuCode=40695
// https://mall.amway.com.cn/productDetail?skuCode=40694
// https://mall.amway.com.cn/productDetail?skuCode=40992
import { images } from 'utils/imgs';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// http://localhost:3000/products

const texts = {
  bread:  breadData.create('Products', 'Products'),
}

function productData(imgUrl, link) {
  return {imgUrl, link}
}

const productList = [
  productData(
    images.root['Product1.png'],
    "https://mall.amway.com.cn/productDetail?skuCode=40695"
  ),
  productData(
    images.root['Product2.png'],
    "https://mall.amway.com.cn/productDetail?skuCode=40694",
  ),
  productData(
    images.root['Product3.png'],
    "https://mall.amway.com.cn/productDetail?skuCode=40992",
  ),
]

const ProductItem = (props) => (
  <Card variant="outlined" className={scssItem.card}>
    <Link to={props.link}>
    <CardActionArea className={scssItem.main}>      
      <CardMedia
        component="img"
        image={props.imgUrl}
      />
    </CardActionArea>
    </Link>
  </Card>
)

//---------------------------------------------------------------------------
const Products = () => {
  return (
    <LayoutPageV2 bread={texts.bread}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {productList.map((item, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <ProductItem imgUrl={item.imgUrl} link={item.link}></ProductItem>
          </Grid>
        ))}
      </Grid>
    </LayoutPageV2>
  )
}

export default Products;