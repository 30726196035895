import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom';

import cookie from 'utils/cookie';
import scss from './ToolItem.module.scss';
import { toolList } from 'views/tool/Tools';
import { cleanEHPToolState } from 'views/tool/ToolEHPToolSearch';
import { cleanHotSpotState } from 'views/tool/ToolHotSpotSearch';
import { cleanAASDState } from 'views/tool/ToolAASD';
import { cleanPDCAASState } from 'views/tool/ToolPDCAAS';
import { cleanCSSToolState } from 'views/tool/ToolCSSToolSearch';
import { cleanAnOxPPState } from 'views/tool/ToolAnOxPP';
import { cleanACEiPPState } from 'views/tool/ToolACEiPP';
import GradientToolCard from './GradientToolCard';
import React from 'react';

//---------------------------------------------------------------------------
const ToolItem = (props) => {
  const { index, title } = props

  const isDisabled = props.link === '';

  const cardStyles = isDisabled
    ? { cursor: 'default', opacity: 0.5 }
    : {};
    
  const muiCard = (props) => {
    return (
    <div className={scss.card}>
      <CardMedia
        className={scss.card_img}
        component="img"
        image={props.imgUrl}
        alt={props.altHint}
      />
      <CardContent className={scss.card_content}>
        <span gutterBottom className={scss.title} component="div">
          {title}
        </span>
        <span className={scss.content}>
          {props.content}
        </span>
      </CardContent>
    </div>)
  }
    
  //---------------------------------------------------------------------------
  return (
    <GradientToolCard disabled={isDisabled} onClick={() => {
      switch( title ) {
        case toolList[(0)].title: //'HotSpot Search', //1
          cleanHotSpotState()
          break
        case toolList[(2-1)].title: //'EHP-Tool', //2
          cleanEHPToolState()
          break
        case toolList[(3-1)].title: //'BPP-Tool', //3
          cookie.setCookie(cookie.keys.peptide.bitterPeptideSearchSeq, "")
          break
        case toolList[(4-1)].title: //'AASD-Tool', //4
          cleanAASDState()
          break
        case toolList[(5-1)].title: //"Peptide Calculator":
          cookie.setCookie(cookie.keys.peptide.calPeptideSearchSeq, "")
          break
        case toolList[(7-1)].title: //'PDCAAS', //7
          cleanPDCAASState()
          break
        case toolList[(9-1)].title: //'CSS-Tool', //9
          cleanCSSToolState()
          break
        case toolList[(10-1)].title: //10
          cleanHotSpotState()
          cleanCSSToolState()
          break
        case toolList[(11-1)].title: //11
          cleanACEiPPState()
          break
        case toolList[(12-1)].title: //12
          cleanAnOxPPState()
          break
        default:
    }}}>

      <div className={scss.main} disabled={isDisabled} style={cardStyles} >
        {isDisabled
        ? muiCard(props)
        : <Link to={props.link}>
            {muiCard(props)}
          </Link>}
      </div>
      
    </GradientToolCard>);
}

export default ToolItem;