import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs } from "@mui/material"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import v2 from 'assets/scss/_variablesV2.scss';
// import scss from './Breadcrumb.module.scss';
import scss2 from './BreadcrumbV2.module.scss';
import Text, { TextLink } from "components/tag/Text"
import links, { linkData } from "utils/links";
import paths from "utils/network/apiPath";
import { getLinkFromArray } from "components/peptide/sppID";

//---------------------------------------------------------------------------
const css = {
  text: 'Contents-QuanticoCaption12px-Regular-White_75',
  // Green Alpha/Alpha_75
  link: 'Contents-QuanticoCaption12px-Regular-Alpha_75 link',
}

const BreadText = (key, text) => (
  <Text key={key} className={css.text}>
    {text}
  </Text>
)

const BreadLink = (key, text) => {
  let link = getLinkFromArray(links.site.bread, text)
  let isValidLink = (link !== '')
  // console.log('bread', links.site.bread);
  // console.log('link', link);
  if( isValidLink )
    return (
      <Link key={key} to={link} className={scss2.link}>
        <Text key={key}
          className={css.link}>
        {text}</Text>
      </Link>)
  else
    return (
      <Text key={key}
          className={css.text}>
        {text}</Text>)
}

const BreadLinkObj = (key, link) => {
  return (
    <TextLink key={key} link={link} className={css.link} />)
}

//---------------------------------------------------------------------------
export default function BreadcrumbV2(props) {
  const { bread } = props
  let location = useLocation();

  useEffect(() => {
    return () => {
    };
  }, [location]);
  
  let text = bread ? bread.text : '...'
  let breadcrumbs = [
    BreadLink(1, 'Home'), // text same with links.site.nav.home
  ]
  
  let nameLv2 = Object.values(links.site.nav).map((item) => {return item.url})
  nameLv2.shift()
  // console.log('nav', links.site.nav);
  // console.log('nameLv2', nameLv2);
  // console.log('location', location);
  if( nameLv2.includes(location.pathname) )
    breadcrumbs.push(BreadText(2, text))
  else
    breadcrumbs.push(BreadLink(2, text))
  
  if(bread !== undefined ) {
    if(bread.text3rd !== '') {
      if( Object.isObject(bread.text3rd) )
        breadcrumbs.push( BreadLinkObj(3, bread.text3rd) )
      else {
        text = bread.text3rd.removeHTMLTags()
        breadcrumbs.push( BreadLink(3, text) )
      }
      
      if( bread.text4st !== '') {
        if( Object.isObject(bread.text4st) )
          breadcrumbs.push( BreadLinkObj(4, bread.text4st) )
        else {
          text = bread.text4st.removeHTMLTags()
          breadcrumbs.push(BreadLink(4, text))
        }
        
        if( bread.text5st !== '') {
          if( Object.isObject(bread.text5st) )
            breadcrumbs.push( BreadLinkObj(5, bread.text5st) )
          else {
            text = bread.text5st.removeHTMLTags()
            breadcrumbs.push(BreadLink(5, text))
          }
  }}}}
  
  // function handleClick(event) {
  //   event.preventDefault();
  // }
  
  //---------------------------------------------------------------------------
  return (
    <div className={scss2.frame}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" sx={{ color: v2.white75}} />}
        {...props}
      >
        {breadcrumbs}
      </Breadcrumbs>
    </div>)
}