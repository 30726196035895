// define BE endpoint url
/* 
class Auth {
    constructor() {
        this.login = '/login'
        this.logout = '/logout'

        // level 2
        // this.log = this.login + '/log' //this.log = /login/log
        class Logins {
            constructor(root) {
                this.log = root + '/log'
            }
        }
        this.logins = new Logins(this.login) //this.logins.log = /login/log
}}
const user = new Auth()
 */
//---------------------------------------------------------------------------
// Authentication
const auth = {
  login: "/v1/auth/login", //1 login
  logout: "/v1/auth/logout", //2 logout
  signup: "/v1/user/register", //4 register
  verify_email: "/v1/auth/verify_email", //3 verify_email
  tnc: "/v1/auth/tnc", //16 get_tnc
};
//---------------------------------------------------------------------------
// Web site
const site = {
  home_number: "/v1/home/portal_summary", //78 portal_summary 7.6
  timeline_list: '/v1/timeline/list', //108 timeline_list
};
//---------------------------------------------------------------------------
//SmartPP database (SPP)
const spp = {
  protein: {
    list: "/v1/proteins/classification_list", //10 proteins_classification_list (PRO04)Endpoint - Classification List
    class_list: "/v1/admin/proteins/all_class", //23 proteins_all_class
    class_list_no_hidden_class : "/v1/home/protein_class_info", //80 protein_class_info
    search: '/v1/proteins/search', //11 proteins_search
    id_search: '/v1/proteins/properties', //21 peptide_properties
    
    fragment: '/v1/tools/cumulative_result', //41 cumulative_result
    frag_graph: '/v1/tools/frag_graph',
    class_count: '/v1/tools/class_count_result', //44 class_count_result
    class_graph: '/v1/tools/db_class_graph',
    
    unique_letter: '/v1/tools/db_unique_letter',
    peptide_list:'/v1/tools/unique_letter_list',
  },
  //---------------------------------------------------------------------------
  peptide: {
    list: "/v1/peptides/classification_list", //8 peptides_classification_list (PEP05)Endpoint - Classification List
    
    quick_search: "/v1/peptides/quick_search", //5 peptides_quick_search
    advanced_search: "/v1/peptides/advanced_search", //6 peptides_advanced_search
    multi_search: "/v1/peptides/multifunctional_search", //7 peptides_multifunctional_search
    quick_class_search: "/v1/peptides/quick_search/classification_search", //12 peptides_classification_quick_search
    class_search: "/v1/peptides/classification_search", //9 peptides_classification_search
    id_search: "/v1/peptides/properties", //21 peptide_properties 2.1.1
    id_precursor_result: "/v1/peptides/precursor_search", //77 peptides_precursor_search 2.1.1
    id_target_protein: "/v1/peptides/target_protein", //88 peptide_target_protein 2.1.1.1
    id_target_protein_page: "/v1/peptides/target_protein_page", //89 target_protein_page 2.1.1.2
    
    pathway: "/v1/peptides/pathway", //52 pathway
    fun_relationship: "/v1/tools/fun_relationship", //48 fun_relationship
    fun_relationship_cross_search: "/v1/tools/fun_relation_detail_search", //67 fun_relation_detail_search
    statistical_data: "/v1/peptides/statistical",
    statistics_list:"/v1/peptides/distinct_classification_list",
    class_advanced_search: "/v1/peptides/classification_advanced_search", //51 classification_advanced_search
    
    inductive_analysis: "/v1/peptides/inductive_analysis", //74 peptides_inductive_analysis
  },
  //---------------------------------------------------------------------------
  tool: {
    // 4.1
    hotspot_protein: '/v1/tools/proteins_name', //38 proteins_name
    hotspot_result: '/v1/tools/hotspot_result', //29 hotspot_result
    hotspot_result_chart: '/v1/tools/multi_hotspot', //42 multi_hotspot
    hotspot_highlight:'/v1/tools/hotspot_highlight', //66 T19 hotspot_highlight 2.1.1.3
    // 4.2
    ehp_tool_enzymes_list: '/v1/tools/enzymatic_tool', //49 enzymatic_tool 4.2
    ehp_tool_enzyme_page: '/v1/tools/enzy_page', //50 enzy_page 4.2
    ehp_tool_result: '/v1/tools/enzymatic_hydrolysis', //64 enzymatic_hydrolysis 4.2.1
    ehp_tool_result_peptide: '/v1/tools/enzymatic_hydrolysis/peptide_result', //68 enzyme_peptide_result 4.2.1
    // 4.3
    bpp_tool_result: "/v1/tools/peptides_bitter_prediction",
    // 4.4
    aasd_tool_upload: '/v1/file/upload',
    aasd_tool_download: '/v1/file/calculate_download',
    // 4.5
    peptide_calculator_result: '/v1/tools/properties_calculator', //18 properties_calculator
    // 4.6
    multi_cross: "/v1/tools/multi_cross", //46 multi_cross
    multi_cross_function: "/v1/tools/multi_cross_function", //47 multi_cross_function
    statistical_nc_table: '/v1/peptides/stat_double_table', //72 statistical_double_table 4.6.1
    statistical_a_table: '/v1/peptides/stat_single_table', //73 statistical_single_table 4.6.1
    statistical_nc_chart: "/v1/peptides/stat_double_graph", //75 statistical_double_graph 4.6.2
    statistical_a_chart: "/v1/peptides/stat_single_graph", //76 statistical_single_graph 4.6.2
    // 4.7
    pdcaas_list: '/v1/tools/pdcaas_drop_down', //98 pdcaas_drop_down
    pdcaas_table: '/v1/tools/dpqehn_list', //97 dpqehn_list
    pdcaas_result: '/v1/tools/pdcaas_tool', //99 pdcaas_tool
    // 4.8
    ppsp_ppd_submit: '/v1/tools/alphafold/job/submit', //109 alphafold_job_submit
    ppsp_ppd_upload: '/v1/tools/alphafold/job/submit', //109 alphafold_job_submit
    ppsp_ppd_job_list: '/v1/tools/alphafold/job/list', //110 alphafold_job_list
    // 4.9
    css_tool_input: '/v1/tools/unique_letter_search_input', //87 unique_letter_search_input
    css_tool_result: '/v1/tools/unique_letter_search', //86 unique_letter_search (T21)Endpoint - Unique Letter Search
    // css_tool_result_lv1_bar_chart: "/v1/tools/unique_letter_chart", //91 unique_letter_chart (T23)Endpoint - Unique Letter Chart
    css_tool_result_lv1_bar_chart: "/v1/tools/duplicate_seq_chart", //94 duplicate_seq_chart (T22)Endpoint - Duplicate Sequence Chart
    css_tool_result_lv1_venn_diagram: '/v1/tools/intersection_chart', //90 intersection_chart (T25)Endpoint - Intersection Chart
    css_tool_result_lv1_venn_diagram_all: '/v1/tools/intersect_duplicate_chart', //96 intersect_duplicate_chart
    // css_tool_result_lv2_bar_chart: '/v1/tools/specified_protein_chart', //92 specified_protein_chart (T24)Endpoint - Specified Protein Chart
    css_tool_result_lv2_bar_chart: '/v1/tools/protein_duplicate_chart', //95 protein_duplicate_chart
    // 4.10
    // 4.11 4.12
    aceipp_anoxpp_manual_result: '/v1/tools/predictor_manual_input', //102 predictor_manual_input T30
    aceipp_anoxpp_manual_mutation: '/v1/tools/predictor_mutation', //103 predictor_mutation T31
    aceipp_anoxpp_seq_chart: '/v1/tools/predictor_stat_chart', //100 predictor_stat_chart T28
    aceipp_anoxpp_pre_pool_table: '/v1/tools/predictor_pool_list', //101 predictor_pool_list T29
    aceipp_anoxpp_upload_result: '/v1/file/predictor_upload_file', //104 predictor_upload_file F05
  },
  
  statistic: {
    portein: '/v1/proteins/statistical', //105 statistical PRO05
    portein_chart: '/v1/proteins/stat_chart', //106 stat_chart PRO06
    // literature_search: '/v1/literature/search', //107 literature_search H03
  },
}

//---------------------------------------------------------------------------
const endpoint = {
  auth, //user
  site,
  spp,
};
export default endpoint;
