//for api 110
//110 alphafold_job_list
export const fakeJobListItems = `
    {
			"job_id": 4,
			"display_name": "NMAINPSKENLCSTFCKB_2024-12-27_10-47-12",
			"job_name": "NMAINPSKENLCSTFCKB_2024-12-27_10-47-12",
			"job_input": {
				"name": "NMAINPSKENLCSTFCKB_2024-12-27_10-47-12",
				"sequences": [
					{
						"protein": {
							"id": [
								"A",
								"B"
							],
							"sequence": "NMAINPSKENLCSTFCKB"
						}
					}
				],
				"modelSeeds": [
					623
				]
			},
			"job_type": "structure_prediction",
			"status": "failed",
			"error_message": "Invalid sequence: non-amino acid letter",
			"created_at": "2024-12-27 10:47:13",
			"modified_at": null,
			"user_id": 1,
			"user_name": "root",
			"user_email": "zymber@zymber.tech"
		},
    {
            "job_id": 5,
            "display_name": "EYKLVVVGAC_2024-12-27_16-51-37",
            "job_name": "EYKLVVVGAC_2024-12-27_16-51-37",
            "job_input": {
                "name": "EYKLVVVGAC_2024-12-27_16-51-37",
                "sequences": [
                    {
                        "protein": {
                            "id": [
                                "A",
                                "B"
                            ],
                            "sequence": "EYKLVVVGAC"
                        }
                    }
                ],
                "modelSeeds": [
                    491
                ]
            },
            "job_type": "structure_prediction",
            "status": "completed",
            "error_message": null,
            "created_at": "2024-12-27 16:51:37",
            "modified_at": "2024-12-27 18:28:34",
            "user_id": 1,
            "user_name": "root",
            "user_email": "zymber@zymber.tech"
        },
        {
            "job_id": 4,
            "display_name": "GAGVSEYELPEDPRWELPRDRLVLGKPLGEGAFGQVVLAEAIGLDKDKPNRVTKVAVKMLKSDATEKDLSDLISEMEMMKMIGKHKNIINLLGACTQDGP_2024-12-27_16-51-34",
            "job_name": "GAGVSEYELPEDPRWELPRDRLVLGKPLGEGAFGQVVLAEAIGLDKDKPNRVTKVAVKMLKSDATEKDLSDLISEMEMMKMIGKHKNIINLLGACTQDGP_2024-12-27_16-51-34",
            "job_input": {
                "name": "GAGVSEYELPEDPRWELPRDRLVLGKPLGEGAFGQVVLAEAIGLDKDKPNRVTKVAVKMLKSDATEKDLSDLISEMEMMKMIGKHKNIINLLGACTQDGP_2024-12-27_16-51-34",
                "sequences": [
                    {
                        "protein": {
                            "id": [
                                "A",
                                "B"
                            ],
                            "sequence": "GAGVSEYELPEDPRWELPRDRLVLGKPLGEGAFGQVVLAEAIGLDKDKPNRVTKVAVKMLKSDATEKDLSDLISEMEMMKMIGKHKNIINLLGACTQDGPLYVIVEYASKGNLREYLQARRPPGLEYSYNPSHNPEEQLSSKDLVSCAYQVARGMEYLASKKCIHRDLAARNVLVTEDNVMKIADFGLARDIHHIDYYKKTTNGRLPVKWMAPEALFDRIYTHQSDVWSFGVLLWEIFTLGGSPYPGVPVEELFKLLKEGHRMDKPSNCTNELYMMMRDCWHAVPSQRPTFKQLVEDLDRIVALTSNQE"
                        }
                    }
                ],
                "modelSeeds": [
                    524
                ]
            },
            "job_type": "structure_prediction",
            "status": "completed",
            "error_message": null,
            "created_at": "2024-12-27 16:51:34",
            "modified_at": "2024-12-27 18:28:34",
            "user_id": 1,
            "user_name": "root",
            "user_email": "zymber@zymber.tech"
        },
        {
            "job_id": 3,
            "display_name": "SVNEVPDYHEDIHTYLREMEVKCKPKVGYMKKQPDITNSMRAILVDWLVEVGEEYKLQNETLHLAVNYI_2024-12-27_16-51-30",
            "job_name": "SVNEVPDYHEDIHTYLREMEVKCKPKVGYMKKQPDITNSMRAILVDWLVEVGEEYKLQNETLHLAVNYI_2024-12-27_16-51-30",
            "job_input": {
                "name": "SVNEVPDYHEDIHTYLREMEVKCKPKVGYMKKQPDITNSMRAILVDWLVEVGEEYKLQNETLHLAVNYI_2024-12-27_16-51-30",
                "sequences": [
                    {
                        "protein": {
                            "id": [
                                "A",
                                "B"
                            ],
                            "sequence": "SVNEVPDYHEDIHTYLREMEVKCKPKVGYMKKQPDITNSMRAILVDWLVEVGEEYKLQNETLHLAVNYI"
                        }
                    }
                ],
                "modelSeeds": [
                    992
                ]
            },
            "job_type": "structure_prediction",
            "status": "completed",
            "error_message": null,
            "created_at": "2024-12-27 16:51:30",
            "modified_at": "2024-12-27 16:57:28",
            "user_id": 1,
            "user_name": "root",
            "user_email": "zymber@zymber.tech"
        }
`