import { TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import 'assets/scss/commonV2.scss';
import 'assets/scss/temp.scss';
import pdrScss from 'views/peptide/PeptideDFBPIDResult.module.scss';

import { breadData } from 'components/bread/BreadBarV2';
import LoadingAnime from 'components/LoadingAnime';
import { TagText } from 'components/tag/Text';
import { TableRow2Body,MuiTableContainer } from 'components/table/MuiTableV2';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTab from 'components/mui/StyledTab';
import StyledTabs from 'components/mui/StyledTabs';
import Text from 'components/tag/Text';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { images } from 'utils/imgs';
import MuiTabPanel, { handleChangeTab, scrollTab } from 'components/mui/MuiTabPanel';
import { handleScroll } from 'components/peptide/sppLayout';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import ResultSidebar from 'components/protein/ResultSidebar';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_bpp_r
// path: paths.spp.tool.bpp_tool_result,
const texts = {
  table: {
    title: [
      'Length', 'Mass', 'pI', 'Net charge', 'GRAVY', 'Hydrophilic residue ratio'
    ]
  },

  tabs: [
    'Overview',
    'References',
  ],
}

class itemData {
  static create(length, mass, pi, netCharge, gravy, hydrophilicResidueRatio) {
    return { length, mass, pi, netCharge, gravy, hydrophilicResidueRatio }
  }

  static output = ['length', 'mass', 'pi', 'netCharge', 'gravy', 'hydrophilicResidueRatio']
}

const MuiTableHead = (props) => (
  <TableHead className="bgc-dark-green">
    {props.children}
  </TableHead>
)

const MuiTableHeadCell = (props) => (
  <MuiTableHead>
    <TableRow>
      <StyledTableCell style={{ color: 'var(--white-white)' }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

const MuiTableHeadCell1st = (props) => (
  <StyledTableCell style={{ width: 190 }} align='left'
    component="th" scope="row" className="bgc-grey50" {...props}>
    {props.children}
  </StyledTableCell>
)

const MuiTableCell = (props) => (
  <StyledTableCell style={{ whiteSpace: 'pre-line' }} {...props}>
    {props.children}
  </StyledTableCell>
)

const rowChildren = (item, index) => {
  return (
    <TableRow2Body key={index}>
      {
        Object.entries(item).map((value) => {
          let unit = "";
          if (value[0] === "mass") {
            unit = "Da"
          }
          return <StyledTableCell key={`${value[0]}_${index}`} align="center" width={50}>
            <TagText html={value[1]} /> {unit}</StyledTableCell>
        })
      }
    </TableRow2Body>
  )
}

//---------------------------------------------------------------------------
const ToolPeptideBitternessPredictResult = ({ setInfo }) => {
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  const words = {
    bread: breadData.create('Tools', 'The bitter prediction results of peptide/protein', bread3rd, 'Result'),
  }

  const keyword = cookie.getCookie(cookie.keys.peptide.bitterPeptideSearchSeq);
  const [sequence, setSequence] = useState("")
  const [resultInMiddle, setResultInMiddle] = useState([])
  const [resultInBottom, setResultInBottom] = useState({})
  const [output, setOutput] = useState({}) //all of output
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const getTheBitterResult = () => {
    const config = apiConfig.tool.bpp_tool_result({
      keyword: removeWhitespaceAndNewlines(keyword)
    })

    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        const data = result.data

        setOutput(data)

        setSequence(data.sequence)

        setResultInMiddle([itemData.create(data.length, data.weight, data.pl, data.netcharge, data.gravyscore, data.ratio)])

        setResultInBottom({
          predicationResult: data["Prediction Result"],
          qValue: data["Q-value"]
        })

      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
      console.error("Proteins error:", err)
    })
  }
  
  function removeWhitespaceAndNewlines(str) {
    return str.replace(/\s+/g, '');
  }
  
  //---------------------------------------------------------------------------
  // TabPanel cell
  const TabCell = (props) => { //show table key&value
    const { tab, output, outputObj } = props
    return (
      <>
        <Text className={'Title-Audiowide28px-Regular-Title'}>{tab.title}</Text>

        {tab.head.map((item, index) => {
          // console.log(index)
          let isHide = false

          return (isHide
            ? null
            : <div key={index} className={pdrScss.right_cell}>
              <div key={`div-${item}`} className={pdrScss.cell_label}>
                <Text key={`text-${item}`} className={'Contents-QuanticoBody16px-Medium-White_75'}>
                  {item}
                </Text>
              </div>
              <div key={`div-${index}`} className={pdrScss.cell_value}>
                <Text key={`text-${index}`} className={'Contents-QuanticoBody16px-Medium-White'}>
                  {renderSubComponent(tab.key[index], tab, index)}
                </Text>
              </div>
            </div>)
        })}
      </>
    )
  }
  
  const renderSubComponent = (key, tab, index) => {
    const value = output[key];

    switch (key) {
      case "weight":
        return `${value} Da`;
      case 'q_value_ranges':
        return <img src={images._03Tools_BPP['bitterPredict1.png']} alt='bitterPredict1' width="100%" height={270} />;
      case 'references':
        return <div>[1] Ney K H. Prediction of bitterness of peptides from their amino acid composition[J]. Z.lebensm.unters.forsch, 1971, 147(2):64-68.<br />
          [2] Ney K H. Bitterness of peptides: amino acid composition and chain length.[C]// ACS Symposium Series. 1979:149–173.<br />
          [3] L. Lemieux, R.E. Simard. Bitter flavour in dairy products. II. A review of bitter peptides from caseins: their formation, isolation and identification, structure masking and inhibition[J]. Dairy Science & Technology, 1992, 72(4):335-385.</div>
      default:
        return value;
    }
  }
  
  //---------------------------------------------------------------------------
  const tabs = {
    overview: {
      head: ["Peptide/Protein sequence", "Length", "Mass", "Pl", "Net charge", "GRAVY", "Hydrophilic residue ratio", "Prediction Result", "Q-value", "Q-value ranges"],
      // json: jsons.output.slice(0, 3 + 1),
      title: texts.tabs[0],
      key: ["sequence", "length", "weight", "pl", "netcharge", "gravyscore", "ratio", "Prediction Result", "Q-value", "q_value_ranges"]
    },
    references: {
      head: ["References"],
      title: texts.tabs[1],
      key: ["references",]
    }
  }
  
  /****************************
   * Single Page to Scroll - Start
   ****************************/
  // TabPanel
  const isShownAllTabs = true //false=multi tabs
  const ref = useRef([])
  const [tabValue, setTabValue] = useState(0);
  
  useEffect(() => {
    ref.current = ref.current.slice(0, texts.tabs.length)
    getTheBitterResult()
    return () => {
    };
  }, []);
  /****************************
   * Single Page to Scroll - End
   ****************************/
  
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
  useEffect(() => {
    window.addEventListener("scroll", () => handleScroll(ref, setTabValue));
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  /********************************************
  * Initialize activeTab & Listen scroll - Start
  ********************************************/
  
  const ContentObjList = [
    <TabCell tab={tabs.overview} output={output} />,
    <TabCell tab={tabs.references} output={output} />,
  ]

  return (
    <LayoutPageV2 bread={words.bread}>
        {state.isLoading ? <LoadingAnime /> :
          <div className={pdrScss.layout_without_pt}>
            <ResultSidebar className={pdrScss.frame_left}>
              <StyledTabs value={tabValue} style={{padding:'20px'}}
                onChange={(e, value) => handleChangeTab(e, value, setTabValue, isShownAllTabs, ref) }>
                {texts.tabs.map((item, index) => (
                  <StyledTab key={index} label={item} index={index} />
                ))}
              </StyledTabs>
            </ResultSidebar>

            <div className={pdrScss.frame_right}>
              {ContentObjList.map((item, index) => (
                <MuiTabPanel key={index} index={index} value={tabValue} tabRef={ref}
                  isShownAllTabs={isShownAllTabs}>
                  {item}
                </MuiTabPanel>
              ))}
            </div>
          </div>
        }
    </LayoutPageV2 >
  )
}
export default ToolPeptideBitternessPredictResult