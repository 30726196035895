import { Box, Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import scss from "./ListAndBarChart.module.scss";
import scssTable from 'components/table/TableV2.module.scss';
import "assets/scss/indexV2.scss"
import LoadingAnime from 'components/LoadingAnime';
import SnackBar, { emptyResult, snackInfo, snackType } from "components/SnackBar";
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch } from "utils/network/axios";
import { spp } from 'utils/network/jsons';
import { MuiTableContainer } from "components/table/MuiTableV2";
import { color } from '@amcharts/amcharts5';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// for
// http://localhost:3000/peptide_fun_rel
const texts = {
  title: {
    no: 'No.',
    peptides: 'Peptides',
    counts: 'Counts',
    viewChart: 'View chart',
    chartSequences: 'Chart / Sequences'
  }
}

//---------------------------------------------------------------------------
const ListAndBarChart = ({ hasSequence = false, ...props }) => {
  const { setInfo } = props
  const [peptideClsList, setPeptideClsList] = useState({}) //peptide_list data


  const getPeptideClsList = () => {
    const config = apiConfig.peptide.list()
    axios(config)
      .then(result => {
        switch (result.data.result_code) {
          case 200:
            let data = result.data[spp.common.output.items]
            setPeptideClsList(result.data)
            props.chartFragmentData.labels = data.map(item => item.classification)
            break;
          default:
            setInfo(snackInfo.openError(result.data.message))
        }

      }).catch(err => {
        let msg = axiosCatch.getMsg(err)
        if (err.code === 'ECONNABORTED') //msg === 'timeout of 5000ms exceeded'
          // setIsTimeout(true)
          setInfo(snackInfo.openError(msg))
      })

  }

  useEffect(() => {
    // getPeptideClsList()
    return () => {
    };
  }, [props.rowData]);

  const MuiTableCell = (props) => (
    <StyledTableCell style={{ whiteSpace: 'pre-line'}} {...props}>
      {props.children}
    </StyledTableCell>
  )

  const MuiTableHeadCell = (props) => (
    <MuiTableCell style={{ backgroundColor: 'var(--green-alpha-alpha-16)', color: 'var(--white-white-75)', borderRight: '1px solid var(--green-alpha-alpha-16)' }} {...props}>
      {props.children}
    </MuiTableCell>
  )

  const navigate = useNavigate()

  const rowChildren = (row, index) => {
    return (
      <TableRow key={row.num} className={scssTable.body_row_interactive} style={{ border: '0px solid red' }}>
        <MuiTableCell align="center" >{index + 1}</MuiTableCell>
        <MuiTableCell className={row.value !== 0 ? scss.peptide_name_link :""}
          onClick={row.value === 0 ? null : () => {
            cookie.setCookie(cookie.keys.peptide.selectedPeptideClsName, row.displayName)
            const oldPeptideTableName = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsTableName)

            cookie.setCookie(cookie.keys.peptide.selectedPeptideClsTableName, row.tableName)
            cookie.setCookie(cookie.keys.peptide.selectedPeptideClsId, row.id)

            if (row.tableName === "multifunctionalpeptides") {
              navigate(paths.spp.peptide.class_search(row.tableName, oldPeptideTableName))
            } else {
              navigate(paths.spp.peptide.class_search(oldPeptideTableName, row.tableName))
            }
          }}>
          {row.displayName}</MuiTableCell>
        <MuiTableCell>{row.value}</MuiTableCell>
      </TableRow>
    )
  }

  const fun_relationships_list = {
    head: ['No.', 'Classification', 'MulPeps'],
  };

  return (
    <LayoutPageV2 bread={props.texts.bread} fontSizeType={props.texts.fontSizeType}>
        {
          props.isLoading ?
            <LoadingAnime /> :
            <>
              <ChartSection
                chartBlockTitle={props.title}
                chartBlockSubTitle={props.subtitle}
                mainChart={
                  <BarChart
                    data={props.chartFragmentData}
                    options={props.chartFragmentOptions}
                    yAxisLabel={props.yAxisLabel} />
                } />


              <Table>
                <TableBody className={scssTable.table_body}>
                  <TableRow>
                    <MuiTableCell>
                      <Box height={30} />
                      <MuiTableContainer size='small' style={{ border: 'none' }}>
                        {fun_relationships_list.head.map((item, index) => (
                          <MuiTableHeadCell key={index} align={index === 0 ? "center" : "left"}>{item}</MuiTableHeadCell>
                        ))}
                        <TableBody style={{ border: 'none', outline: 'none' }} >
                          {props.rowData.map((item, index) => rowChildren(item, index))}
                        </TableBody>
                      </MuiTableContainer>

                    </MuiTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
        }
        
    </LayoutPageV2>

  )
}
export default ListAndBarChart