/* 
Loading
https://git.hust.cc/echarts-for-react/examples/loading
echarts-for-react - npm
https://www.npmjs.com/package/echarts-for-react
Examples - Apache ECharts
https://echarts.apache.org/examples/zh/editor.html?c=radar
https://echarts.apache.org/examples/zh/editor.html?c=radar-custom
 */
import React, { useState, useEffect } from 'react';
// import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import 'assets/scss/indexV2.scss'
/* 
  let radarInd = []
  radarInd.push( chartProp.data.radarIndicator('sales', 6500) )
  radarInd.push( chartProp.data.radarIndicator('Administration', 16000) )
  radarInd.push( chartProp.data.radarIndicator('Information Techology', 30000) )
  let radarSeries = []
  radarSeries.push( chartProp.data.radarSeries(
    'Allocated Budget', [4300, 10000, 28000]))
  radarSeries.push( chartProp.data.radarSeries(
    'Actual Spending', [5000, 14000, 28000]))
  
  let radarData = chartProp.data.createRadar('Budget vs spending', radarInd, radarSeries)
  
  <ERadarChart data={radarData} />
*/
//---------------------------------------------------------------------------
const ERadarChart = ({ data, options, ...props }) => {
  // console.log('data', data);
  if( data === undefined ) {
    data.series = []
    data.indicator = []
    data.title = ''
  }
  if( data.series === undefined )
    data.series = []
  
  const _legend = data.series.map((item) => item.name)
  const _indicator = data.indicator
  if( _indicator !== undefined && Array.isArray(_indicator) ) {
    _indicator[0].axisLabel = {
      show: true,
      color:'#4bbfa4',
  }}
  console.log(_indicator);
  
  const _options = {
    color: ['#56A3F1', '#FF917C', '#67F9D8', '#FFE434', ],
    title: {
      top: 0,
      x: 'center',
      text: data.title,
      textStyle:{
        color: 'white',
        fontFamily:'Quantico'
      }
    },
    tooltip: {}, //default tooltip
    // Documentation - Apache ECharts
    // https://echarts.apache.org/en/option.html#legend
    // https://stackoverflow.com/questions/75233779/echart-react-legend-icon-and-label-custom-styling
    // grid: {
    //   top: 80, // the size of title + legend + margin
    // },
    legend: {
      // icon: 'circle',
      // orient: 'vertical',
      top: 24, // the size of title + margin or '30%'
      data: _legend,
      textStyle: {
        color: '#fff',
        // fontSize: '14px',
        width: 400,
        overflow: 'break',
      },
    },
    
    radar: {
      // shape: 'circle',
      // splitNumber: 4,
      indicator: _indicator,
      axisName: {
        // formatter: '【{value}】',
        // backgroundColor: '#666',
        // borderRadius: 3,
        // padding: [3, 5],
        color:'#fff'
      },
    },
    series: [{
      // name: 'Budget vs spending',
      type: 'radar',
      // areaStyle: {normal: {}},
      data: data.series,
      // symbolSize: 12,
      // label: {
      //   show: true,
      //   formatter: 'This is a normal label.' // 标签的文字。
      // },
    }],
  }
  
  if( options !== undefined )
    Object.assign(_options, options)
  console.log('_options', _options);
  // console.log('options', options);

  return <ReactECharts
    option={_options}
    style={{ height: 500 }}
    {...props}
  />
}

export default ERadarChart