import React, { useEffect } from 'react'
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import scss from './HomePage.module.scss'
import axios, { apiConfig, axiosCatch, axiosState } from "utils/network/axios";
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import cookie from 'utils/cookie';
import ContactV2 from 'layouts/home/ContactV2';
import PurposeV2 from 'layouts/home/PurposeV2';
import SearchV2 from 'layouts/home/SearchV2';

//---------------------------------------------------------------------------
const HomePage = ({ setInfo }) => {
  const [output, setOutput] = useState([])
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiNumber = () => {
    const config = apiConfig.site.home_number() //78
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let output = result.data
        setOutput(output)
        // console.log('output', output)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      apiNumber()
  }, [])
  
  return (
    <div className={scss.layout}>
      <SearchV2 output={output} setInfo={setInfo} />
      <PurposeV2 />
      <ContactV2 />
    </div>);
};

export default HomePage;
