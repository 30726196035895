import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CountUp from 'react-countup';

import v from 'assets/scss/_variables.scss';
import v2 from 'assets/scss/_variablesV2.scss';
import scss2 from './ProteinListV2.module.scss'
import { breadData } from 'components/bread/BreadBarV2';
import LoadingAnime from 'components/LoadingAnime';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import ImgCard from 'components/protein/ImgCard';
import cookie from 'utils/cookie';
import imgs from 'utils/imgs';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import { Button, Drawer, IconButton } from '@mui/material';
import NoneButton from 'components/button/NoneButton';
import Text from 'components/tag/Text';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import GradientSidebar from 'components/protein/GradientSidebar';

//---------------------------------------------------------------------------
// http://localhost:3000/proteins
// path: paths.site.proteins,
const isHardCodeSort = true
const jsons = spp.protein.list
const texts = {
  bread: breadData.create('Protein', 'Food-borne proteins'),
  
  total_list: 'Total',
}

// pr=protein
export class proteinData {
  static sort(name, id, results = 0) {
    return { name, id, results }
  }
  static sort2nd(sort1st_name, lists, results = 0) {
    return { 'name': sort1st_name, lists, results }
  }
  // static source(name, id) {}
  static create(sort, source, counts) {
    return { sort, source, counts }
  }
  static init() {
    return {
      sort: '-',
      source: '-',
      counts: 0
    }
  }
}

export const initSorts = [
  proteinData.sort('Plant', 220000),
  proteinData.sort('Algae', 0), //藻類
  proteinData.sort('Mushroom', 0), //菇類
  proteinData.sort('Yeast/Bacteria', 550000), //酵母菌/細菌
  // proteinData.sort('Micro-organism', 550000),
  proteinData.sort('Marine', 440000), //海洋
  proteinData.sort('Animal', 330000),
  proteinData.sort('Milk', 110000),
  proteinData.sort('Others', 0),
]

const initSorts2nd = [
  proteinData.sort2nd('Plant', ['Legume', 'Cereal', 'Seed', 'Nut', 'Flower', 'Herb/leaf',	'Vegetable', 'Fruit',] ),
  // proteinData.sort2nd('Animal', ['Chicken', 'Bovine', 'Pig', 'Dog', 'Dove', 'Rabbit', 'Goat', 'Sheep', 'Horse','Fish']),
  // proteinData.sort2nd('Milk', ['Human', 'Cow', 'Camel', 'Horse', 'Rabbit', 'Kangaroo', 'Sheep', 'Goat']),
]

function indexData(start, end) {
  return { start, end }
}

//---------------------------------------------------------------------------
const ProteinList = ({ setInfo }) => {
  const [cardIndex, setCardIndex] = useState([])
  const [listAll, setListAll] = useState()
  const [listSelect, setListSelect] = useState(() => {return {species: 0, results: 0}}) //with counts
  const [sortSelect, setSortSelect] = useState([]) //only array
  const [sorts, setSorts] = useState([])
  const [sortName, setSortName] = useState('')
  const [sortImageName, setSortImageName] = useState('')
  // console.log('sortName', sortName);
  const sorts2nd = initSorts2nd
  const [isShowSort2nd, setIsShowSort2nd] = useState(Array(initSorts2nd.length).fill(false))
  const [total, setTotal] = useState(0)
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiList = () => {
    const config = apiConfig.protein.list() //10
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        // const items = [] //debug
        const items = result.data[spp.common.output.items]
        // console.log('items', items);
        
        let sorts = (isHardCodeSort ? initSorts : getSorts(items))
        
        let total = 0
        let sortMax = 0
        let list = []
        sorts.forEach(function (sort) {
          // console.log('sort.id', sort.id)
          let ans = items.filter(function (item) {
            // eslint-disable-next-line
            return (item[jsons.items.class_id] == sort.id)
          })
          // console.log('ans', ans)
          if (sortMax < ans.length)
            sortMax = ans.length
          let h = Number(scss2.paddingHeight) + Math.ceil(ans.length / 3) * Number(scss2.barHeight); //216 + y*144
          if (Math.ceil(ans.length / 3) < 3)
            h = Number(scss2.paddingHeight) + 3 * Number(scss2.barHeight); //min height
          // console.log('h', h)
          let obj = {
            sort: sort.name,
            sort_id: sort.id,
            species: ans.length,
            results: ans.reduce((sum, item) => {
              return (sum + item[jsons.items.counts])
            }, 0),
            height: h,
            items: ans,
          }
          // console.log('obj', obj)
          list.push(obj)
          total += ans.reduce((sum, item) => {
              return (sum + item[jsons.items.counts])
            }, 0)
          return null
        })
        setListAll(list)
        // console.log('list', list)
        setTotal(total)
        // console.log('total', total)
        
        sorts.forEach((item, index) => (
          item.results = list[index].results
        ))
        setSorts(sorts)
        // console.log('sorts', sorts);
        
        let card = []
        for (const i of Array(Math.ceil(sortMax/4)).keys()) 
            card.push(indexData(i * 4, (i * 4) + 4))       
        setCardIndex(card)
        // console.log('sortMax', sortMax)
        // console.log('card', card);
        
        if( sorts.length > 0 ) {
          let sortName = sorts[0].name
          
          let sortLast = cookie.getCookie(cookie.keys.protein.listSelectClsName);
          sortName = sortLast ? sortLast : sortName
          // console.log(sortName);
          
          setSortName(sortName)
          setSortImageName(sortName)
          changeSort(list, sortName)
        }
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      apiList()
  }, [sorts, isShowSort2nd])
  
  function getSorts(items) {
    if( Array.isArray(items) ) {
      let arys = items.map(item => proteinData.sort(item[jsons.items.class], Number(item[jsons.items.class_id])))
      let ary = arys.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.name === value.name && t.id === value.id
        ))
      )
      // console.log(ary);
      return ary
    }
    return initSorts
  }

  const handleChangeSort = (event, lists, name) => {
    // console.log('>handleChangeSort');
    
    event.preventDefault()
    if (event.target === event.currentTarget)
      changeSort(lists, name)
  }
  function changeSort(lists, name) {
    // console.log(name);
    const clsId = lists.filter(item => item.sort === name)
      cookie.setCookie(cookie.keys.tool.ehpSelectedClsID, clsId[0].sort_id)
      
      setSortName(name)
      setSortImageName(name)
      setSelect(lists, name)
  }
  const handleMouseOverSort = (lists, name) => {
    // console.log(name);
    setSortImageName(name)
    setSelect(lists, name)
  }
  const handleMouseOut = (lists) => {
    let name = sortName
    setSortImageName(name)
    setSelect(lists, name)
  }
  function setSelect(lists, name) {
    if (lists !== undefined) {
      let sort = getListSelect(lists, name);
      // console.log('sort', sort);
      setListSelect(sort)
      setSortSelect(sort.items)
    }
  }
  function getListSelect(lists, name) {
    const temp = lists.filter(function (list) {
      return (list.sort === name)
    })
    // console.log('temp', temp);
    return (temp[0])
  }
  function handleClickSort2nd(index) {
    let tmp2ndList = [...isShowSort2nd]
    tmp2ndList[index] = !tmp2ndList[index]
    setIsShowSort2nd(tmp2ndList)
    // console.log('isShowSort2nd', isShowSort2nd);
  }
  
  //------------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread}>
      <div className={scss2.frame_bottom}>
        {state.isLoading ? <LoadingAnime /> :
          <> 
          <GradientSidebar>
            <div className={scss2.bottom_sort}>
              
              <div className={scss2.sort_total}>
                <div className={scss2.sort_title} style={{cursor:'pointer'}} onClick={() => {
                  cookie.setCookie(cookie.keys.protein.listSelectClsName, '');
                  
                  cookie.setCookie(cookie.keys.protein.classGraphSelectedClsID, "")
                  cookie.setCookie(cookie.keys.protein.classGraphSelectedClsName, "")
                  cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceID, "")
                  cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceName, "Food-borne")
                  cookie.setCookie(cookie.keys.peptide.selectedMultiPeptideList, [])
                  navigate(paths.spp.protein.source_search())
                }}>
                    <Text
                      className='Contents-QuanticoBody16px-Regular-White_80-Sidebar-title'>
                      {`${texts.total_list} (${total})`}
                    </Text>
                </div>
              </div>
              
              {sorts.map((item) => (
                <React.Fragment key={item.name}>
                  <div className={scss2.sort_mike} key={item.name}
                    onClick={(event) => handleChangeSort(event, listAll, item.name)}
                  >
                    <div className={scss2.sort_title} style={{ cursor: 'pointer' }}>
                      <Text
                        className={`Contents-QuanticoBody16px-Regular-White_80-Sidebar ${
                          sortImageName === item.name ? 'active' : ''
                        }`}
                        onClick={() => {
                        
                          setSortImageName(item.name); 
                          
                          cookie.setCookie(cookie.keys.protein.listSelectClsName, item.name);

                          cookie.setCookie(cookie.keys.protein.classGraphSelectedClsID, item.id);
                          cookie.setCookie(cookie.keys.protein.classGraphSelectedClsName, item.name);
                          cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceID, "");
                          cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceName, "");
                          cookie.setCookie(cookie.keys.peptide.selectedMultiPeptideList, []);
                          navigate(paths.spp.protein.source_search());
                        }}
                      >
                        {`${item.name} (${item.results})`}
                      </Text>
                    </div>
                    
                    {sorts2nd.map((e, idx) => {
                      // console.log('item.name', item.name);
                      // console.log('e.name', e.name);
                      let isShowList = isShowSort2nd[idx]
                      if(e.name === item.name ) {
                        return (
                        <IconButton onClick={() => handleClickSort2nd(idx)} key={idx}>
                          {isShowList ? <ExpandLessIcon sx={{ color: 'white' }} /> :<ExpandMoreIcon sx={{ color: 'white' }} />}
                        </IconButton>)
                      }
                      return null
                    })
                    }
                  </div>
                  
                  {sorts2nd.map((e, idx) => {
                    // console.log(e);
                    let isShowList = isShowSort2nd[idx]
                    if(e.name === item.name && isShowList ) {
                      // console.log(e);
                      return (
                      e.lists.map((list) => {
                        return (
                          <div className={scss2.sort2nd} key={item.name}>
                            <Text className='Contents-QuanticoCaption12px-Regular-White_50'>{list}</Text>
                          </div>)}))
                    }
                    return null
                  })}
                </React.Fragment>
              ))}
              
            </div> {/* bottom_sort */}
            </GradientSidebar>
            
            <div className={scss2.bottom_list}>
              <div className={scss2.result_texts}>
                <div className={scss2.result_item}>
                  <Text className={'Title-Audiowide12px-Regular-White_75'}>
                    <CountUp
                      start={0}
                      end={listSelect.species}
                      duration={1}
                    />
                  </Text>
                  <Text className={'Contents-QuanticoCaption12px-Regular-Alpha_60'}>
                    species
                  </Text>
                </div>

                <div className={scss2.result_item}>
                  <Text className='Title-Audiowide12px-Regular-White_75'>
                    <CountUp
                      start={0}
                      end={listSelect.results}
                      duration={1}
                    />
                  </Text>
                  <Text className='Contents-QuanticoCaption12px-Regular-Alpha_60'>
                    results
                  </Text>
                </div>
              </div>

              <div className={scss2.cards_block}>
                {cardIndex.map((value, index) => {
                  // console.log('cardIndex=', cardIndex)
                  return (
                    sortSelect.slice(value.start, value.end).length > 0 
                    && <div className={scss2.list_bar} key={value.start} style={{ marginTop: index !== 0 ? "40px" : "0px" }}>
                      {sortSelect.slice(value.start, value.end).map((item, index) => {
                        let idx = index + (value.start)
                        if (sortImageName === 'Plant')
                          idx += 10 //index from imgList imgList.protein.jpgProtein
                        else if (sortImageName === 'Animal')
                          idx += 10+24+18+22+4
                        else if (sortImageName === 'Marine')
                          idx += 10+24+18
                        else if (sortImageName === 'Micro-organism' || sortImageName === 'Yeast/Bacteria')
                          idx += 10+24+18+22
                        // console.log(idx);
                        return (
                          <ImgCard key={item.source} cardData={item} imgName={imgs.spp.protein.list[idx]}></ImgCard>
                        )
                      })}
                    </div>)
                })
                }
              </div>
            </div>
          </>
        }
      </div>
    </LayoutPageV2>
  )
}

export default ProteinList;
