import { Box, Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import scssTable from 'components/table/TableV2.module.scss';

import { breadData } from 'components/bread/BreadBarV2';
import LoadingAnime from 'components/LoadingAnime';
import { TagText } from 'components/tag/Text';
import SnackBar, { emptyResult, snackInfo, snackType } from "components/SnackBar";
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { isEmptyObjOrArray } from 'utils/general';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { sortByKey } from 'utils/object';
import { BorderRight } from '@mui/icons-material';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// http://localhost:3000/protein_frag_graph
// path: paths.spp.protein.frag_graph(),
const fontSizeOfLongText = 30;

//---------------------------------------------------------------------------
const ProteinFragGraph = ({ setInfo }) => {
  // console.log('具體內容:', JSON.stringify(scssTable, null, 2));

  const [list, setList] = useState({}) //peptide_list data
  const [items, setItems] = useState([]) //items data in peptide_list

  const [peptideClsObj, setPeptideClsObj] = useState([])
  const [peptideAnalysisLabels, setPeptideAnalysisLabels] = useState([])
  const [peptideAnalysisValue, setPeptideAnalysisValue] = useState([])

  const [sumPeptide, setSumPeptide] = useState(0)
  const [totalSeq, setTotalSeq] = useState(0)

  const [selectedProteinSourceName, setSelectedProteinSourceName] = useState("")


  let texts = {
    bread: breadData.create('Protein', `Accumulated sequence analysis of bioactive peptides encoded in ${selectedProteinSourceName} proteins`, '', '', '', fontSizeOfLongText),

    title: ["No", "Peptides", "Counts"]
  }

  const words = {
    chart_fragment: {
      title: `The count of bioactive peptides accumulated in ${selectedProteinSourceName} protein`,
      text: `Sum results: `,
      text2: `Peptide sequences results: `,
      x: 'Classification',
      y: 'Fragment count',
    }
  }

  const chartFragmentOptions = chartProp.options.axisTitleXY(words.chart_fragment.x, words.chart_fragment.y)
  const chartFragmentData = chartProp.data.carate(
    "",
    "",
    [''],
    peptideAnalysisLabels,
    peptideAnalysisValue
  )

  const MuiTableCell = (props) => (
    <StyledTableCell {...props}>
      {props.children}
    </StyledTableCell>
  )

  const MuiTableHeadCell = (props) => (
    <MuiTableCell {...props}>
      {props.children}
    </MuiTableCell>
  )

  const MuiTableContainer = (props) => (
    <TableContainer variant="outlined" className={scssTable.table_container}>
      <Table aria-label="a dense table" {...props}>
        {props.children}
      </Table>
    </TableContainer>
  )

  const rowChildren = (row, index) => {
    return (
      <TableRow key={row.num}>
        <MuiTableCell align="center">{index + 1}</MuiTableCell>
        <MuiTableCell><TagText html={row[0]} /></MuiTableCell>
        <MuiTableCell><TagText html={row[1]} /></MuiTableCell>
      </TableRow>
    )
  }
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const getPeptideClsList = () => {

    // setSumPeptide(fakeResult.sum)
    // setTotalSeq(fakeResult.cumulative_sequences)

    const selectedProteinSourceId = cookie.getCookie(cookie.keys.protein.classGraphSelectedSourceID)
    const selectedProteinClsId = cookie.getCookie(cookie.keys.protein.classGraphSelectedClsID)

    let input = {}

    if (selectedProteinClsId !== "" && selectedProteinClsId !== undefined) {
      input["class_code"] = selectedProteinClsId
    } else {
      if (selectedProteinSourceId !== "" && selectedProteinSourceId !== undefined) {
        input["source_code"] = selectedProteinSourceId
      } else {
        // Total Protein list
        input["source_code"] = "000000"
      }
    }


    const config = apiConfig.protein.frag_graph(input)
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        const data = result.data["items"];
        // console.log('items', data);

        if (isEmptyObjOrArray(data)) {
          setInfo(snackInfo.openInfo(emptyResult))
          return null;
        }
        
        const sortedData = sortByKey(data)        
        setPeptideClsObj(sortedData)
        // console.log('sortedData', sortedData);

        setTotalSeq(result.data["total peptides"])

        let sum = 0;
        setPeptideAnalysisLabels(Object.keys(sortedData).map(item => item))
        setPeptideAnalysisValue(Object.values(sortedData).map(item => {
          sum += item
          return item
        }))

        setSumPeptide(sum)

      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    getPeptideClsList()
    const selectedSourceName = cookie.getCookie(cookie.keys.protein.classGraphSelectedSourceName)
    const selectedClsName = cookie.getCookie(cookie.keys.protein.classGraphSelectedClsName)


    if (selectedClsName !== "" && selectedClsName !== undefined) {
      setSelectedProteinSourceName(selectedClsName)
    } else {
      if (selectedSourceName !== "" && selectedSourceName !== undefined) {
        setSelectedProteinSourceName(selectedSourceName)
      }
    }

    return () => {
    };
  }, []);



  return (
    <LayoutPageV2 bread={texts.bread} fontSizeType={texts.fontSizeType}>
      
        {
          state.isLoading ?
            <LoadingAnime /> :
            <>
              <ChartSection
                chartBlockTitle={words.chart_fragment.title}
                chartBlockSubTitle={words.chart_fragment.text}
                chartBlockSubTitleNumber={sumPeptide}
                chartBlockSubTitle2={words.chart_fragment.text2}
                chartBlockSubTitleNumber2={totalSeq}
                mainChart={
                  <BarChart
                    data={chartFragmentData}
                    options={chartFragmentOptions}
                    yAxisLabel={words.chart_fragment.y} />
                } />

              <Box height={30} />
              
              <MuiTableContainer size='small'>
                {texts.title.map((item, index) => (
                  <MuiTableHeadCell className={scssTable.header_cell} key={index} align={index === 0 ? "center" : "left"}>{item}</MuiTableHeadCell>
                ))
                }
                <TableBody className={scssTable.table_body}>
                  {Object.entries(peptideClsObj).map((item, index) => rowChildren(item, index))}

                  <TableRow>
                    <MuiTableCell></MuiTableCell>
                    <MuiTableCell style={{fontWeight: '600'}}>Sum</MuiTableCell>
                    <MuiTableCell style={{fontWeight: '600'}}>{sumPeptide}</MuiTableCell>
                  </TableRow>

                  <TableRow>
                    <MuiTableCell></MuiTableCell>
                    <MuiTableCell style={{fontWeight: '600'}}>Cumulative sequences</MuiTableCell>
                    <MuiTableCell style={{fontWeight: '600'}}>{totalSeq}</MuiTableCell>
                  </TableRow>
                </TableBody>
              </MuiTableContainer>
            </>

        }
        
    </LayoutPageV2>


  )
}
export default ProteinFragGraph