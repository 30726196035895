import React, { useEffect, useState, useRef } from 'react';
// import { Box, Button } from '@mui/material';
// import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import v2 from 'assets/scss/_variablesV2.scss';
import scss from './NavbarV2.module.scss';
import NoneButton from 'components/button/NoneButton';
import SnackBar, { snackInfo } from "components/SnackBar";
import cookie from 'utils/cookie';
import imgs from 'utils/imgs';
import links from 'utils/links';
import paths from 'utils/network/apiPath';
import axios, { apiConfig, axiosCatch } from "utils/network/axios";
import Text, { TextLink } from 'components/tag/Text';
import { ImgLink } from 'components/tag/Img';
import { DivLink } from 'components/tag/Div';
import SearchV2 from './home/SearchV2';
import SearchBarV2 from './home/SearchBarV2';

//---------------------------------------------------------------------------
const texts = {
  statistics: [
    'Protein',
    'Peptide',
    // 'Literature',
  ],
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    minWidth: 110,
    color: v2.white50,
    background: 'rgba(3, 9, 9, 1)',
    // background:' linear-gradient(0deg, #030909, #030909), radial-gradient(41.57% 41.57% at 41.87% -0.09%, rgba(100, 255, 218, 0.3) 0%, rgba(10, 10, 10, 0) 100%)',
    
    border: '1px solid',
    // Gradient/Line 1
    // borderImageSource: "url( '/assets/img/Styles/Line 1.svg') )", //not work //todo
    // borderImageSlice: 1,

    '& .MuiMenu-list': {
      // padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      // '& .MuiSvgIcon-root': {
      // },
      '&:hover': {
        // backgroundColor: '#f0f',
        color: v2.white,
      },
      '&:active': {
        // backgroundColor: '#0ff',
      },
    },
  },
}));

//------------------------------------------------------------------------------
export default function NavbarV2({ setInfo }) {
  const [isSearchDisplay, setIsSearchDisplay] = useState(false);
  const [isClickSearchBtn, setIsClickSearchBtn] = useState(false);
  const [isSearchBar, setIsSearchBar] = useState(false);
  const [isHomepage, setIsHomepage] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let location = useLocation();
  let pathsName = location.pathname

  // console.log(Site.Company.AboutUs);
  // console.log(Site.Company.AboutUs.link);
  const [linkLog, setLinkLog] = useState('');
  const [userToken, setUserToken] = useState('');

  // let email = cookie.getCookie(cookie.keys.auth.email)
  //console.log("email",email);
  // const accountName = email.split('@')[0];
  // const userName = accountName.charAt(0).toUpperCase() + accountName.slice(1);
  const userName = cookie.getCookie(cookie.keys.auth.nickname)
  //console.log("userName",userName);

  let token = cookie.getCookie(cookie.keys.auth.token)
  if (token !== userToken)
    setUserToken(token)
  // console.log('token', token);
  // console.log('userToken', userToken);

  function removeAllCookie() {
    cookie.removeCookie(cookie.keys.auth.token)
    cookie.removeCookie(cookie.keys.auth.id)
    cookie.removeCookie(cookie.keys.auth.nickname)
  }

  useEffect(() => {
    if (pathsName === '/') {
      setIsHomepage(true);
    } else {
      setIsHomepage(false);
    }
  }, [pathsName]);

  useEffect(() => {
    // console.log(linkList.nav.auth.login);
    // console.log(linkList.nav.auth.logout);
    if (userToken === '')
      setLinkLog(links.auth.login)
    else
      setLinkLog(links.auth.logout)
    // console.log('linkLog', linkLog);
  }, [userToken])
  
  const navigate = useNavigate()
  const logoutEvent = () => {
    const config = apiConfig.auth.logout(cookie.getCookie(cookie.keys.auth.token))
    // console.log(config);

    axios(config)
      .then(result => {
        switch (result.data.result_code) {
          case 200:
            removeAllCookie()
            setInfo(snackInfo.openSuccess("Logout Successfully"))
            navigate(paths.site.home)
            break;
          default:
            setInfo(snackInfo.openError(result.data.reason))
        }

      }).catch(err => {
        // console.error("Logout error:", err)
        let msg = axiosCatch.getMsg(err)
        let code = axiosCatch.getResultCode(err)
        // console.log('msg', msg);
        // console.log('code', code);
        setInfo(snackInfo.openError(msg))
        if (axiosCatch.needLogin(err))
          cookie.removeCookieAndJump(navigate, location)
      })
  }

  const handleClickLog = () => {
    if (userToken === '')
      navigate(paths.auth.login)
    else
      logoutEvent()
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };
  //------------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const onStatisticsClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };
  const onStatisticsClose = (item) => {
    setAnchorEl(null);
    setIsMenuOpen(false);
    
    // console.log('item', item);
    switch(item) {
      case texts.statistics[0]:
        navigate(paths.spp.statistic.protein())
        break
      case texts.statistics[1]:
        // console.log('1');
        navigate(paths.spp.statistic.peptide())
        break
      default:
        break
    }
  };
  
  const css = {
    //styleName: Contents: Quantico/Body/16px: Regular;
    text_menu: 'Contents-QuanticoBody16px-Regular-White_50-Btn',
    text_user: 'Contents-QuanticoBody16px-Regular-White_50',
  }

  return (
    <div className={scss.layout}>
      <div className={scss.frame_all}>
        <div className={scss.frame_left}>
          <ImgLink imgName={imgs.siteV2.nav.logo} link={links.site.nav.home} className={scss.logo}></ImgLink>
          
          <div className={scss.frame_menu}>
            <DivLink className={scss.menu_item}
              text={css.text_menu} link={links.site.nav.proteins} />
            <DivLink className={scss.menu_item}
              text={css.text_menu} link={links.site.nav.peptides} />
            <DivLink className={scss.menu_item}
              text={css.text_menu} link={links.site.nav.tools} />
              
            <DivLink 
              className={`${scss.menu_item} ${isMenuOpen ? scss.menu_item_active : ''}`}
              text={css.text_menu} link={links.site.nav.statistics} 
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={onStatisticsClick}
              style={{cursor: 'pointer'}}
            />
              <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {texts.statistics.map((item, index) => (
                  <MenuItem key={index} onClick={() => onStatisticsClose(item)}>
                    {item}
                  </MenuItem>
                ))}
              </StyledMenu>
            
            <DivLink className={scss.menu_item}
              text={css.text_menu} link={links.site.nav.products} />
            <DivLink className={scss.menu_item}
              text={css.text_menu} link={links.site.nav.about} />
          </div>
        </div>
        
        <div className={scss.frame_right}>
          {!isHomepage && !isSearchDisplay &&
            <IconButton
              size="large"
              onClick={() => {
                setIsSearchDisplay(true)
              }}>
              <SearchIcon fontSize="small" sx={{ color: v2.white50 }} />
            </IconButton>
          }
          {isSearchDisplay &&
            <div className={scss.frame_search}>
              <SearchBarV2 setInfo={setInfo}></SearchBarV2>
              <IconButton
                size="large"
                onClick={() => {
                  setIsSearchDisplay(false)
                }}>
                <KeyboardDoubleArrowRightIcon sx={{ color: v2.white50 }} />
              </IconButton>
            </div>
          }
          
          <DivLink className={scss.button_signup}
            text={`${css.text_user} hover-cursor-pointer`} onClick={handleClickLog}>
              {linkLog.text}
          </DivLink>
          
          <div className={scss.line_6} />
          
          {(userToken !== "" && userToken !== undefined)
            ? <div className={scss.right_user}>
              <Text className={css.text_user}>{userName}</Text>
              </div>
            : <div className={scss.button_signup}>
                <TextLink pre className={css.text_user} link={links.auth.signup}></TextLink>
              </div >
          }
        </div >
      </div>
      
      {/* <div className={scss.frame_light}>
        <div className={scss.line} >
          <div className={scss.light_line3} />
          <div className={scss.light_line4} />
        </div>
      </div> */}
    </div >
  )
}