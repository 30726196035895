import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, IconButton, TableHead, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import 'assets/scss/indexV2.scss';
import scss from './ToolPeptideBitternessPredict.module.scss';
import scssTable from 'components/table/TableV2.module.scss'

import { breadData } from 'components/bread/BreadBarV2';
import { TagText } from 'components/tag/Text';
import { TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { checkInvalidSeq, getFullPath, isOnlySpacesOrNewlines } from 'utils/general';
import paths from "utils/network/apiPath";
import StyledTextField from 'components/tool/StyledTextField';
import { MuiTableContainer } from "components/table/MuiTableV2";
import { linkData } from 'utils/links';
import GreenButton from 'components/button/GreenButton';
import LayoutPageV2 from 'layouts/LayoutPageV2';


//---------------------------------------------------------------------------
// http://localhost:3000/tool_bpp
// path: paths.spp.tool.bpp_tool,
const texts = {
  bread: breadData.create('Tools', 'The Bitterness of Peptide Prediction Tool (BPP-Tool)', 'BPP-Tool'),

  desc: 'BPP-Tool is to predict whether the peptide has a bitter taste based on peptide sequence characteristics.',
  input: 'Sequences input',

  searchHint: ' Please enter protein/peptide sequence with single letters or sequences',
  required: 'Required',

  table: {
    text: 'Free energy of transfer (△f and △F) of the side chains of amino acids, representing their hydrophobicity.',
    title: [
      'Amino acid', 'Three-letter', 'Single letter', 'R groups (cal/mol)', '△f-value (cal/mol)', '△F-value', 'Bitterness'
    ]
  }
}

class itemData {
  static create(amino, three, single, r_groups, f_value, f_value2, bitterness) {
    return { amino, three, single, r_groups, f_value, f_value2, bitterness }
  }

  static output = ['amino', 'three', 'single', 'r_groups', 'f_value', 'f_value2', 'bitterness']
}

const nonBitterItems = [
  itemData.create('Glutamine', 'Gln', 'Q', '2', '-100', '+970', 'Non-bitter amino acids'),
  itemData.create('Asparagine', 'Asn', 'N', '2', '-10', '+890'),
  itemData.create('Glycine', 'Gly', 'G', '2', '0', '+810'),
  itemData.create('Serine', 'Ser', 'S', '2', '40	', '+420'),
  itemData.create('Threonine', 'Thr', 'T', '2', '440', '	+290'),
  itemData.create('Histidine', 'His', 'H', '3', '500', '+690'),
  itemData.create('Aspartic acid', 'Asp', 'D', '4', '540', '+610'),
  itemData.create('Glutamic acid', 'Glu', 'E', '4', '550', '+510'),
  itemData.create('Arginine', 'Arg', 'R', '3', '730', '+690'),
  itemData.create('Alanine', 'Ala', 'A', '1', '730', '+610'),
  itemData.create('Cysteine', 'Cys', 'C', '2', '0', '+360'),
]

const bitterItems = [
  itemData.create('Glutamine', 'Gln', 'Q', '2', '-100', '+970', 'Bitter amino acids'),
  itemData.create('Methionine', 'Met', 'M', '1', '1300', '-660'),
  itemData.create('Lysine', 'Lys', 'K', '3', '1500', '+460'),
  itemData.create('Valine', 'Val', 'V', '1', '1690', '-750'),
  itemData.create('Leucine', 'Leu', 'L', '1', '2420', '-1650'),
  itemData.create('Proline', 'Pro', 'P', '1', '2620', '-170'),
  itemData.create('Phenylalanine', 'Phe', 'F', '1', '2650', '-1520'),
  itemData.create('Tyrosine', 'Tyr', 'Y', '2', '2870', '-1430'),
  itemData.create('Isoleucine', 'Ile', 'I', '1', '2970', '-1450'),
  itemData.create('Tryptophan', 'Trp', 'W', '1', '3000', '-1200'),
]

const MuiTableHead = (props) => (
  <TableHead className="bgc-dark-green">
    {props.children}
  </TableHead>
)

const MuiTableHeadCell = (props) => (
  <MuiTableHead>
    <TableRow>
      <StyledTableCell style={{ color: 'var(--white-white-75)' }} {...props}>
        {props.children}
      </StyledTableCell>
    </TableRow>
  </MuiTableHead>
)

//---------------------------------------------------------------------------
const ToolPeptideBitternessPredict = ({ setInfo }) => {
  let location = useLocation()
  let bread = linkData('BPP-Tool', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log('bread3rd', bread);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  
  const textAreaRef = useRef();
  const handleFocus = () => {
    textAreaRef.current.focus();
  };
  
  const navigate = useNavigate()
  
  //---------------------------------------------------------------------------
  // Sequences input
  const [keyword, setKeyword] = useState('')
  const [isSearchError, setSearchError] = useState(false)
  const [isSeqInputError, setIsSeqInputError] = useState(false)
  const [seqInputErrMsg, setSeqInputErrMsg] = useState("")
  const handleChangeSearch = (value) => {
    // console.log('value', value);
    setKeyword(value)
    if (value === "") {
      setIsSeqInputError(false)
      setSeqInputErrMsg("")
    }
    setSearchError(false)
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter')
      handleClickSearch()
  }
  const handleClickSearch = () => {
    const isInvalidCharObj = checkInvalidSeq(keyword);
    const onlySpaceNewlines = isOnlySpacesOrNewlines(keyword)

    if (isInvalidCharObj.isInvalid) {
      setIsSeqInputError(true)
      setSeqInputErrMsg(isInvalidCharObj.errMsg)
      setInfo(snackInfo.openError(isInvalidCharObj.errMsg))
    } else if (onlySpaceNewlines.isInvalid) {
      setIsSeqInputError(true)
      setSeqInputErrMsg(onlySpaceNewlines.errMsg)
      setInfo(snackInfo.openError(onlySpaceNewlines.errMsg))
    }
    else {
      setIsSeqInputError(false)
      setSeqInputErrMsg("")


      if (keyword !== '' && !onlySpaceNewlines.isInvalid) {
        cookie.setCookie(cookie.keys.peptide.bitterPeptideSearchSeq, keyword)
        navigate(paths.spp.tool.bpp_tool_result())
      }
      else {
        // const errMsg = "Please enter the protein/peptide sequence";
        setSearchError(true)
        setIsSeqInputError(true)
        setSeqInputErrMsg(onlySpaceNewlines.errMsg)
        // handleFocus()
        setInfo(snackInfo.openError(onlySpaceNewlines.errMsg))
      }
    }
  }
  
  //---------------------------------------------------------------------------
  // Table
  const rowChildren = (item, index, allDataLen) => {
    return (
      <TableRow2Body key={index}>
        {
          Object.entries(item).map((value, idx) => {
            let align = "center"
            if( idx === 0 )
              align = 'left'
            if (index === 0 && value[0] === 'bitterness') {
              return <StyledTableCell key={value[0]} rowSpan={allDataLen} align={align}>{value[1]}</StyledTableCell>
            } else {
              if (value[0] !== 'bitterness') {
                return <StyledTableCell key={value[0]} align={align}><TagText html={value[1]} /></StyledTableCell>
              }
              return null
            }
          })
        }
      </TableRow2Body>

      // {index === 0 && <TableRow2Body>
      //   <StyledTableCell rowSpan={item.details.length + 1}>{item.type}</StyledTableCell>
      // </TableRow2Body>}

    )
  }
  
  //---------------------------------------------------------------------------
  useEffect(() => {
    const bitterPeptideSearchSeq = cookie.getCookie(cookie.keys.peptide.bitterPeptideSearchSeq)

    if (bitterPeptideSearchSeq !== undefined) {
      setKeyword(bitterPeptideSearchSeq)
    }
    return () => {
    };
  }, []);
  
  return (
    <LayoutPageV2 bread={texts.bread} desc={texts.desc}>

        <ToolSingleBlock title={texts.input}
          mainBlockContent={
            <div colSpan={texts.table.title.length} className={scss.top_block}>
              <div className={scss.search_block}>
                <StyledTextField
                  // sx={{ m: 0, flex: 1, width: '97%'}}
                  placeholder={texts.searchHint}
                  // label={texts.required}
                  label={texts.searchHint}
                  required
                  value={keyword}
                  onChange={(event) => { handleChangeSearch(event.target.value) }}
                  onKeyDown={handleKeyDown}
                  error={isSeqInputError}
                  helperText={seqInputErrMsg}
                />
                <GreenButton aria-label="search" onClick={handleClickSearch}>
                  Search
                </GreenButton>
              </div>
            </div>} />

        <ToolSingleBlock title={texts.table.text} mainBlockContent={<MuiTableContainer size="small" >
          <TableHeadRow >
            {texts.table.title.map((title, index) => (
              <StyledTableCell className={scssTable.header_cell} key={index} style={{ color: (index === 2) ? 'var(--green-alpha-alpha-50)' : 'var(--white-white-75)' }}
                align={ index === 0 ? 'left' : "center"}>
                <TagText html={title} /></StyledTableCell>
            ))}
          </TableHeadRow>

          <TableBody>
            {nonBitterItems.map((item, index) => (
              rowChildren(item, index, nonBitterItems.length)
            ))}
          </TableBody>

          <TableBody>
            {bitterItems.map((item, index) => (
              rowChildren(item, index, bitterItems.length)
            ))}
          </TableBody>

          <TableBody>
            <TableRow2Body>
              <StyledTableCell align="center">Reference</StyledTableCell>
              <StyledTableCell colSpan={6} >[1] Ney K H. Prediction of bitterness of peptides from their amino acid composition[J]. Z.lebensm.unters.forsch, 1971, 147(2):64-68.<br />
                [2] Ney K H. Bitterness of peptides: amino acid composition and chain length.[C]// ACS Symposium Series. 1979:149–173.<br />
                [3] L. Lemieux, R.E. Simard. Bitter flavour in dairy products. II. A review of bitter peptides from caseins: their formation, isolation and identification, structure masking and inhibition[J]. Dairy Science & Technology, 1992, 72(4):335-385.</StyledTableCell>
            </TableRow2Body>
          </TableBody>

        </MuiTableContainer>
        } />
    </LayoutPageV2>
  )
}
export default ToolPeptideBitternessPredict