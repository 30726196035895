import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { breadData } from 'components/bread/BreadBarV2';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import SearchFullResultLayout from 'layouts/search/SearchFullResultLayout';
import cookie from 'utils/cookie';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import paths from 'utils/network/apiPath';

//---------------------------------------------------------------------------
// http://localhost:3000/protein_peptide_list?id=SPPPR7720&cls=renin_inhibitory_peptides
// Go in: Protein -> 'Bovine Milk proteins' -> choose SPP ID 'SPPPR8484'-> Peptide List table ->Functional classification
// path: paths.spp.protein.peptide_list(),
const ProteinPeptideList = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const classId = searchParams.get(paths.params.class)
  // const className = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsName)
  // const classNameParts = className.split('peptides');
  // const extractedClassName = classNameParts[0];
  const [className, setClassName] = useState("");
  const [state, setState] = useState(axiosState.init())

  const dfbpid = searchParams.get(paths.params.id)
  // console.log('dfbpid', dfbpid,"className",className)

  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  let bread4th = cookie.getCookie(cookie.keys.protein.bread4th)
  // console.log('bread4th', bread4th);
  let texts = {
    bread: breadData.create('Protein', className, bread3rd, bread4th, className.trimClassifyName()),

    searchHint: `${className} encoded in Transforming growth factor beta-2 proprotein`,
    tableHeadCell: spp.protein.search.peptide_list.output_text,
    tableBodyCell: spp.protein.search.peptide_list.output,
  }

  const navigate = useNavigate()
  let location = useLocation()
  const apiList = () => {
    const config = apiConfig.peptide.list()
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())

        const selectedPeptide = result.data['item(s)'].find(item => item.classification_col === classId)
        if(selectedPeptide !== undefined){
          setClassName(selectedPeptide.classification)
        }

        // const displayNameArray = selectedPeptide.classification.split('peptides')
        // setExtractedClassName(displayNameArray[0])

      }
    }).catch(err => {
      console.log('err', err)
      
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }


  useEffect(() => {
    apiList()
  }, []);

  return (
    <>
      <SearchFullResultLayout
        setInfo={setInfo}
        texts={texts}
        configSearch={apiConfig.protein.peptide_list}
        jsonInput={spp.protein.search.peptide_list.input}
        inputProp={[dfbpid, classId]}
        jsonInputKeyword={spp.protein.search.peptide_list.input_keyword}
      />
    </>
  )
}
export default ProteinPeptideList