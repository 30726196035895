import { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";

import { spp } from 'utils/network/jsons';
import { apiConfig } from 'utils/network/axios';
import { breadData } from 'components/bread/BreadBarV2';
import SearchResultLayout from 'layouts/search/SearchResultLayout';
import cookie from 'utils/cookie';
import paths from 'utils/network/apiPath';

//---------------------------------------------------------------------------
// http://localhost:3000/peptide_ia?keyword=LLEYSI&cls=antiviral_peptides
// Go in: 'Peptide' page -> any item list -> any 'SPP ID' -> click 'Inductive Analysis' button -> this page
// path: paths.spp.protein.inductive_analysis ,
const jsonInductive = spp.peptide.search.inductive_analysis
const PeptideInductiveAnalysisResult = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const queryClass = searchParams.get(paths.params.class)
  const keyword = searchParams.get(paths.params.keyword)
  const [breadTitle, setTableTitle] = useState("")
  
  let tableSelect = {}
  switch (queryClass) {
    case "ace_inhibitory_peptides": //no.1
    case "vasorelaxation_peptides": //no.27
    case "vasoconstriction_peptides": //no.28
      tableSelect.tableHeadCell = jsonInductive.items_text_ic50
      tableSelect.tableBodyCell = jsonInductive.items_ic50
      break
    default:
      tableSelect.tableHeadCell = jsonInductive.items_text
      tableSelect.tableBodyCell = jsonInductive.items
  }
  // console.log('queryClass', queryClass);
  // console.log('tableSelect', tableSelect);
  
  let texts = {
    bread: breadData.create('Peptide', breadTitle),
    
    searchHint: "",
    tableHeadCell: tableSelect.tableHeadCell,
    tableBodyCell: tableSelect.tableBodyCell,
  }

  useEffect(() => {
    const peptideName = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsName)
    if (peptideName) //NOT undefined, null, ""
      setTableTitle(`${peptideName}: ${keyword}`)
  }, [])

  return (
    <SearchResultLayout
      setInfo={setInfo}
      texts={texts}
      configSearch={apiConfig.peptide.inductive_analysis} //74
      jsonInput={jsonInductive.input}
      inputProp={[queryClass, keyword]}
    />
  )
}
export default PeptideInductiveAnalysisResult