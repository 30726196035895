import { CardActionArea, TableBody, TableHead, TableRow } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import cookie from 'utils/cookie';
import scss from './StatCard.module.scss';
import { TableHeadRow, TableRow2Body } from 'components/table/MuiTable';
import StyledTableCell from 'components/table/StyledTableCell';
import { TagText } from 'components/tag/Text';
import paths from 'utils/network/apiPath';
import useLocationSPP from 'utils/location';

//------------------------------------------------------------------------------

class itemData {
  static output = [
    "source_total",
    "frag_total",
    "avg_count",
    "frag_graph",
    "peptide_total",
    "peptide_graph",
  ]
  
  static output_text = [
    "Proteins",
    "Fragment\nCount",
    "Average\nCount",
    "Fragment\nGraph",
    "Class-\nCount",
    "Class-\nGraph",
  ]
}

//---------------------------------------------------------------------------
export default  function StatCard(props) {
  const { title, item } = props
  // console.log('item', item);
  
  const arrItem = itemData.output.map(key => [key, item[key] ?? ""])
  // console.log(arrItem);
  const name = item ? item.name : ''
  const code = item ? item.code : '0' //"code": "110000",
  // console.log(code);
  let color = item ? item.color : ''
  // '#9F8975', '#4CAF50', '#8C4D25', '#1A5F73', '#7A3FA7'
  
  let location = useLocationSPP()
  const isLv1 = !location.isStatisticProteinLv2
  const link = isLv1 ? paths.spp.statistic.protein_lv2(code) : null
    
  //---------------------------------------------------------------------------
  // Table
  // const [hoverIndex, setHoverIndex] = useState(null);
  const rowChildren = (items, index) => {
    return (
      <TableRow2Body
        // onMouseEnter={() => setHoverIndex(index)}
        // onMouseLeave={() => setHoverIndex(null)}
      >
        {
          Object.entries(items).map((values, idx) => {
            // console.log(value);
            let key = values[0]
            let value = values[1]
            
            let align = "right"
            if( idx === 0 ) {
              align = 'left'
              let item = itemData.output_text[index]
              return (
                <StyledTableCell key={key} align={align} className={scss.table_key}>
                  <span className={scss.text_key}>
                    {item}
                  </span>
                </StyledTableCell>)
            } else {
              let className = scss.text_value_link
              if(index === 2)
                className = scss.text_value
              if( value === '' )
                value = 'View'
              return (
                <StyledTableCell key={key} align={align} className={scss.table_value}>
                  <span className={className} onClick={() => jump(index)}>
                    {value}
                  </span>
                </StyledTableCell>)
            }
          })
        }
    </TableRow2Body>
    )
  }
  
  const navigate = useNavigate()
  function jump(index) {
    // const name = item.name
    jumpSetting(code, name)
    switch (index) {
      case 0:
        // 'Proteins'
        navigate(paths.spp.protein.source_search())
        break;
      case 1:
        //'Fragment Count'
        navigate(paths.spp.protein.fragment_count(code))
        break;
      case 3:
        // "Fragment Graph" Frag-Graph
        navigate(paths.spp.protein.frag_graph())
        break;
      case 4:
        //"Classification Count" 'Classification Count'
        navigate(paths.spp.protein.class_count(code))
        break
      case 5:
        // "Classification Graph" Class-Graph
        navigate(paths.spp.protein.class_graph())
        break;
    }
  }
  
  function jumpSetting(id, name) {
    // console.log(id, name);
    if(isLv1) {
      cookie.setCookie(cookie.keys.protein.classGraphSelectedClsID, id)
      cookie.setCookie(cookie.keys.protein.classGraphSelectedClsName, name)
      cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceID, "")
      cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceName, "")
    } else {
      cookie.setCookie(cookie.keys.protein.classGraphSelectedClsID, "")
      cookie.setCookie(cookie.keys.protein.classGraphSelectedClsName, "")
      cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceID, id)
      cookie.setCookie(cookie.keys.protein.classGraphSelectedSourceName, name)
    }
    cookie.setCookie(cookie.keys.peptide.selectedMultiPeptideList, [])
  }
  
  //---------------------------------------------------------------------------
  return (
    <div className={scss.main}>
      <TableHeadRow  style={{background: color}}>
        <StyledTableCell className={scss.title}>
          {link 
          ? <Link to={link}>
              <span className={scss.text_title_link}>
                {item.name}
              </span>
            </Link>
          : <span className={scss.text_title}>
              {item.name}
            </span>
          }
        </StyledTableCell>
      </TableHeadRow>

      <TableBody >
        {arrItem.map((item, index) => (
          // rowChildren(item, index, setHoverIndex)
          rowChildren(item, index)
        ))}
      </TableBody>
    </div>);
}