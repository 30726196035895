import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
// import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// import { Box, Popper, TableHead } from '@mui/material';
// import { TableCell } from '@mui/material';
import { CategoryScale } from 'chart.js';
import Chart from "chart.js/auto";
// import { styled } from '@mui/material/styles';

import 'assets/scss/common.scss';
import 'assets/scss/index.scss';
import 'assets/scss/temp.scss';
import v from 'assets/scss/_variables.scss';
// import { NoneDiv } from 'components/GreenDiv';
// import { TableHeadRow, TableRow2Body } from 'components/MuiTable';
import SnackBar, { emptyResult, snackInfo, snackType } from 'components/SnackBar';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
// import paths from 'utils/network/apiPath';
// import linkList from 'utils/linkList';
import { breadData } from 'components/bread/BreadBarV2';
import BarChart from 'components/chart/BarChart';
import ChartSection from 'components/chart/ChartSection';
import chartProp from 'components/chart/chartProp';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { Space, determineHasEmptyListInTwoLevelObj } from 'utils/general';
import paths from 'utils/network/apiPath';
import { MuiTableContainer } from "components/table/MuiTable";
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// http://localhost:3000/peptide_stat_data
// Go in: 'Protein' page -> any card button -> any 'SPP ID' -> this page
// path: paths.spp.peptide.statistical_data(),
const jsons = spp.protein.search.id

const fontSizeOfLongText = 40;

function rowData(name, value) {
  return { name, value };
}

const MuiTableCell = (props) => (
  <StyledTableCell style={{ fontSize: 20, whiteSpace: 'pre-line' }} {...props}>
    {props.children}
  </StyledTableCell>
)

const MuiTableHeadCell = (props) => (
  <MuiTableCell className="bgc-dark-green color-white" {...props}>
    {props.children}
  </MuiTableCell>
)

const MuiTableCell1st = (props) => (
  <MuiTableCell component="th" scope="row" {...props}>
    {props.children}
  </MuiTableCell>
)

const MuiTableHeadCell1st = (props) => (
  <StyledTableCell style={{ fontSize: 20, width: 190 }} align='right'
    component="th" scope="row" className="bgc-grey50" {...props}>
    {props.children}
  </StyledTableCell>
)

Chart.register(CategoryScale);
Chart.defaults.font.size = 16;
//---------------------------------------------------------------------------
const PeptideStatisticalData = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const indexOfDFBPID = searchParams.get(paths.params.id)
  // console.log(input);

  const [output, setOutput] = useState({}) //all of output
  const [list, setList] = useState({}) //peptide_list data
  const [items, setItems] = useState([]) //items data in peptide_list

  const [selectedClsName, setSelectedClsName] = useState("")
  const [totalSeq, setTotalSeq] = useState(0);


  const fontSizeOfLongText = 40;
  const texts = {
    bread: breadData.create('Tools', `Sequence characteristics of ${selectedClsName}`, '', '', '', fontSizeOfLongText),

    dfbp: {
      title: 'SmartPP ID - ',
      // 'SPP ID', 'Protein name', 'Type', 'Length',
      head: spp.protein.search.id.output_text.slice(0, 4),
      json: spp.protein.search.id.output.slice(0, 4),
    },

    names: {
      title: 'Names & Taxonomy',
      // 'Classification', 'Organism', 'Protein name',
      head: //4, 5, 1
        spp.protein.search.id.output_text.slice(4, 6).concat(
          spp.protein.search.id.output_text.slice(1, 2)
        ),
      json:
        spp.protein.search.id.output.slice(4, 6).concat(
          spp.protein.search.id.output.slice(1, 2)
        ),
    },

    protein: {
      title: 'Protein sequence',
      head: spp.protein.search.id.output_text.slice(6, 8),
      json: spp.protein.search.id.output.slice(6, 8),
    },

    table: {
      title: 'Multifunctional peptide list and related analysis',
      head: [
        'Peptide List',
      ],
      json: ['',],

      list: {
        head: ['No.', 'Classification', 'Multifunction', 'Peptide', 'Ratio'],
        cell: spp.protein.search.id.items,
      },
    },

    statistics: {
      title: 'Statistics View: Sequence coding analysis of bioactive peptides encoded in Transforming growth factor beta-2 proprotein',
    },

    enzymatic: {
      title: 'Enzymatic hydrolysis prediction',
      head: [
        'Enzymatic Hydrolysis',
      ],
      json: ['',],
      link: 'SPP---Enzymatic Hydrolysis Prediction Tool (EHP-Tool)',
    },

    cross: {
      title: 'Cross-references',
      head: [
        spp.protein.search.id.output_text[9], // 'UniProt',
      ],
      json: [
        spp.protein.search.id.output[9],
      ],
    },
  }


  const words = {
    ncTerminalCoding: {
      title: 'N- and C-terminal amino acid coding features',
      text: `${selectedClsName} sequences: `,
      x: 'Amino acids',
      y: 'Amino acid percentage(%)',
    },
    distributionNTerminal: {
      title: 'The distribution of amino acids, from site 1 to 10 on N-terminal of peptides',
      text: `${selectedClsName} sequences: `,
      x: 'Amino acids',
      y: 'Amino acid percentage(%)',
    },
    distributionCTerminal: {
      title: 'The distribution of amino acids, from site 1 to 10 on C-terminal of peptides',
      text: `${selectedClsName} sequences: `,
      x: 'Amino acids',
      y: 'Amino acid percentage(%)',
    },
    lengthDistributionPeptides: {
      title: 'The length distribution characteristics of bioactive peptides',
      text: `${selectedClsName} results: `,
      x: 'Peptide length',
      y: 'Percentage(%)',
    },
    aminoAcidPeptides: {
      title: 'The amino acid composition characteristics of bioactive peptides',
      text: `${selectedClsName} results: `,
      x: 'Amino acid',
      y: 'Percentage(%)',
    },
    molecularWeight: {
      title: 'The molecular weight (MW) distribution characteristics of bioactive peptides',
      text: `${selectedClsName} results: `,
      x: 'MW distribution',
      y: 'Percentage(%)',
    },
  }


  // 1
  const [ncTerminalCodingLabels, setNcTerminalCodingLabels] = useState([]);
  const [ncTerminalCoding_N_Value, setNcTerminalCoding_N_Value] = useState([]);
  const [ncTerminalCoding_C_Value, setNcTerminalCoding_C_Value] = useState([]);

  const ncTerminalCodingOptions = chartProp.options.axisTitleXY(words.ncTerminalCoding.x, words.ncTerminalCoding.y,true)
  const ncTerminalCodingData = chartProp.data.carateMulti(
    "",
    "",
    ncTerminalCodingLabels,
    [chartProp.data.singleDataset(
      "N-Terminal", ncTerminalCoding_N_Value, v.green, v.chartHoverGreen
    ), chartProp.data.singleDataset(
      "C-Terminal", ncTerminalCoding_C_Value, v.purple, v.hoverPurple
    )])

  // 2
  const [distributionNTerminalLabels, setDistributionNTerminalLabels] = useState([]);
  const [distributionNTerminal_P1_P10_Value, setDistributionNTerminal_P1_P10_Value] = useState([]);

  const bgColors = [
    "#0dddcd", "#1CFCBE", "#29FCCF", "#36FCE0", "#43F7F2",
    "#50EDF3", "#5DD1F2", "#6AB5F1", "#7799F1", "#856DF0"
  ]

  const bgHoverColors = [
    "#2FEBD3", "#2DFFE1", "#3AFFF2", "#47FFF3", "#54FFF5",
    "#61F8F6", "#6EDAF5", "#7BBEF4", "#88ACF4", "#978FF3"
  ]


  const distributionNTerminalOptions = chartProp.options.axisTitleXY(words.distributionNTerminal.x, words.distributionNTerminal.y,true)
  const distributionNTerminalData = chartProp.data.carateMulti(
    "",
    "",
    distributionNTerminalLabels,
    distributionNTerminal_P1_P10_Value.map((item, index) => {
      return chartProp.data.singleDataset(
        `Position ${index + 1}`, item, bgColors[index], bgHoverColors[index]
      )
    }),
  )

  // 3
  const [distributionCTerminalLabels, setDistributionCTerminalLabels] = useState([]);
  const [distributionCTerminal_P1_P10_Value, setDistributionCTerminal_P1_P10_Value] = useState([]);

  const distributionCTerminalOptions = chartProp.options.axisTitleXY(words.distributionCTerminal.x, words.distributionCTerminal.y,true)
  const distributionCTerminalData = chartProp.data.carateMulti(
    "",
    "",
    distributionCTerminalLabels,
    distributionCTerminal_P1_P10_Value.map((item, index) => {
      return chartProp.data.singleDataset(
        `Position ${index + 1}`, item, bgColors[index], bgHoverColors[index]
      )
    }),
  )

  // 4
  const [lengthDistributionPeptidesLabels, setLengthDistributionPeptidesLabels] = useState([]);
  const [lengthDistributionPeptidesValue, setLengthDistributionPeptidesValue] = useState([]);

  const lengthDistributionPeptidesOptions = chartProp.options.axisTitleXY(words.lengthDistributionPeptides.x, words.lengthDistributionPeptides.y)
  const lengthDistributionPeptidesData = chartProp.data.carate(
    "",
    "",
    [''],
    lengthDistributionPeptidesLabels,
    lengthDistributionPeptidesValue,
  )

  // 5
  const [aminoAcidPeptidesLabels, setAminoAcidPeptidesLabels] = useState([]);
  const [aminoAcidPeptidesValue, setAminoAcidPeptidesValue] = useState([]);

  const aminoAcidPeptidesOptions = chartProp.options.axisTitleXY(words.aminoAcidPeptides.x, words.aminoAcidPeptides.y)
  const aminoAcidPeptidesData = chartProp.data.carate(
    "",
    "",
    [''],
    aminoAcidPeptidesLabels,
    aminoAcidPeptidesValue,
  )

  // 6
  const [molecularWeightLabels, setMolecularWeightLabels] = useState([]);
  const [molecularWeightValue, setMolecularWeightValue] = useState([]);

  const molecularWeightOptions = chartProp.options.axisTitleXY(words.molecularWeight.x, words.molecularWeight.y)
  const molecularWeightData = chartProp.data.carate(
    "",
    "",
    [''],
    molecularWeightLabels,
    molecularWeightValue,
  )



  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const id = cookie.getCookie(cookie.keys.peptide.selectedPeptideClsId)
    let input = {}
    input["class_id"] = Number(id);

    const config = apiConfig.peptide.statistical_data(input) //17
    // console.log(input);
    // console.log(config);

    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let output = result.data
        // setOutput(output)

        setTotalSeq(output.total)

        // 1 set ncTerminalCoding
        setNcTerminalCodingLabels(output.features.amino_acids)
        setNcTerminalCoding_N_Value(output.features.n_terminal)
        setNcTerminalCoding_C_Value(output.features.c_terminal)

        // 2 set distributionNTerminal
        setDistributionNTerminalLabels(output.n_terminal.amino_acids)
        setDistributionNTerminal_P1_P10_Value(Object.values(output.n_terminal).slice(1));

        // 3 set distributionCTerminal
        setDistributionCTerminalLabels(output.c_terminal.amino_acids)
        setDistributionCTerminal_P1_P10_Value(Object.values(output.c_terminal).slice(1));

        // 4 set lengthDistributionPeptides
        setLengthDistributionPeptidesLabels(output.length_distribution.peptide_length)
        setLengthDistributionPeptidesValue(output.length_distribution.percentage)

        // 5 set aminoAcidPeptides  
        setAminoAcidPeptidesLabels(output.amino_acid.amino_acids)
        setAminoAcidPeptidesValue(output.amino_acid.percentage)

        // 6 set molecularWeight
        setMolecularWeightLabels(output.molecular_weight.MW_distribution)
        setMolecularWeightValue(output.molecular_weight.percentage)

        // Check result is empty
        if (determineHasEmptyListInTwoLevelObj(output)) {
          setInfo(snackInfo.openInfo(emptyResult))
        }
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      apiResult()
  }, [])


  useEffect(() => {
    setSelectedClsName(cookie.getCookie(cookie.keys.peptide.selectedPeptideClsName))
  }, [])


  return (
    <LayoutPageV2 bread={texts.bread}>

        <ChartSection
          chartBlockTitle={words.ncTerminalCoding.title}
          chartBlockSubTitle={words.ncTerminalCoding.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart
              data={ncTerminalCodingData}
              options={ncTerminalCodingOptions}
              showTableLabel={true}
              yAxisLabel={words.ncTerminalCoding.y}
            />
          } />

        <Space />
        <ChartSection
          chartBlockTitle={words.distributionNTerminal.title}
          chartBlockSubTitle={words.distributionNTerminal.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart
              data={distributionNTerminalData}
              options={distributionNTerminalOptions}
              yAxisLabel={words.distributionCTerminal.y}
              showTableLabel={true} />
          } />

        <Space />
        <ChartSection
          chartBlockTitle={words.distributionCTerminal.title}
          chartBlockSubTitle={words.distributionCTerminal.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart data={distributionCTerminalData}
              options={distributionCTerminalOptions}
              yAxisLabel={words.distributionCTerminal.y}
              showTableLabel={true} />
          } />

        <Space />
        <ChartSection
          chartBlockTitle={words.lengthDistributionPeptides.title}
          chartBlockSubTitle={words.lengthDistributionPeptides.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart
              data={lengthDistributionPeptidesData}
              options={lengthDistributionPeptidesOptions}
              yAxisLabel={words.lengthDistributionPeptides.y} />
          } />

        <Space />
        <ChartSection
          chartBlockTitle={words.aminoAcidPeptides.title}
          chartBlockSubTitle={words.aminoAcidPeptides.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart
              data={aminoAcidPeptidesData}
              options={aminoAcidPeptidesOptions}
              yAxisLabel={words.aminoAcidPeptides.y} />
          } />

        <Space />
        <ChartSection
          chartBlockTitle={words.molecularWeight.title}
          chartBlockSubTitle={words.molecularWeight.text}
          chartBlockSubTitleNumber={totalSeq}
          mainChart={
            <BarChart
              data={molecularWeightData}
              options={molecularWeightOptions}
              yAxisLabel={words.molecularWeight.y} />
          } />
          
    </LayoutPageV2>
  )
}
export default PeptideStatisticalData