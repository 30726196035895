import React from "react";
import BuildIcon from '@mui/icons-material/Build';
import AddchartIcon from '@mui/icons-material/Addchart';

import scss from './ChangelogCard.scss.module.scss';
import { Link } from "react-router-dom";
import jsons, { spp } from "utils/network/jsons";

//------------------------------------------------------------------------------
const classNameColor = {
  date: 'green',
  function: 'purple',
}

const jsonsList = jsons.site.timeline_list
//------------------------------------------------------------------------------
export default function ChangelogCard({ time, content,  size = 'normal' }) {
  // console.log('content', content);
  const arrContent = Array.isArray(content) ? content : [content]
  let item = arrContent[0]
  if( !item )
    item = jsonsList.item()
  const classCard = item.is_data ? classNameColor.date : classNameColor.function
  const isData = item.is_data
  const isMulti = (arrContent.length >= 2)
  // console.log(isMulti);

  const baseCardClass = size === 'large' ? scss.timelineCardLarge : scss.timelineCard
  
  function getIcon() {
    return (isData ? <AddchartIcon fontSize="small" /> : <BuildIcon fontSize="small" />)
  }
  
  function getNo(index) {
    // return (isMulti ? <span>{`${index+1}.`}</span> : null)
    return (isMulti ? <span>{`· `}</span> : null)
  }
  
  function getCount() {
    if(isData)
      return (` +${item.entry}`)
    else
      return null
  }
  
  //------------------------------------------------------------------------------
  return (
    <div className={`${baseCardClass} ${scss[classCard]}`}>
      <div className={scss.cardHeader}>
        {getIcon()}{time}
      </div>
      <div className={scss.cardContent}>
        {arrContent.map((item, index) => {
          // console.log(index, item);
          return (
            <React.Fragment key={index}>
              {getNo(index)}
              {item.has_link && item.link_url && !isData
              ? <a 
              href={item.link_url} 
              className={scss.link}
              target="_blank"
              rel="noopener noreferrer"
            >
                  <span>{item.content}<br /></span>
                </a>
              : <span>{item.content}{getCount()}<br /></span>}
            </React.Fragment>)
        })}
      </div>
    </div>
  );
};
