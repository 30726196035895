// import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
// import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import StyledTableCell from 'components/table/StyledTableCell';

import scssTable from 'components/table/TableV2.module.scss'

import LoadingAnime from 'components/LoadingAnime';
import { TagText } from 'components/tag/Text';
import { MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import SnackBar, { snackInfo, snackType } from "components/SnackBar";
import cookie from 'utils/cookie';
// import linkList from 'utils/linkList';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import { breadData } from 'components/bread/BreadBarV2';
import { linkData } from 'utils/links';
import { getFullPath } from 'utils/general';
import LayoutPageV2 from 'layouts/LayoutPageV2';

//---------------------------------------------------------------------------
// http://localhost:3000/peptide_quick
// path: paths.spp.peptide.quick_search(),
const jsons = spp.peptide.search.quick

const MuiTableHeadCell = (props) => (
  <StyledTableCell {...props}>
    {props.children}
  </StyledTableCell>
)

const StyledTableCell1st = (props) => (
  <StyledTableCell component="th" scope="row" align="right">
    {props.children}
  </StyledTableCell>
)

const rowChildren = (item, index, navigate) => {
  let path = ''
  let display_name = item[jsons.items[1]]
  let results = item[jsons.items[2]]
  if(results !== 0)
    path = paths.spp.peptide.quick_class_search(item.classification)
  
  return (
    <TableRow2Body key={index} 
    className={scssTable.body_row_interactive}
      onClick={ async() => {
        await cookie.setCookie(cookie.keys.peptide.selectedPeptideClsName, display_name)
        navigate(path)
      }}
      style={{ cursor: results !== 0 ? "pointer" : "auto" }}>
      <StyledTableCell1st>{index + 1}</StyledTableCell1st>
      <StyledTableCell><TagText html={`${display_name} peptides`} /></StyledTableCell>
      <StyledTableCell><TagText html={results} /></StyledTableCell>
    </TableRow2Body>)
}
//---------------------------------------------------------------------------
const PeptideQuickSearchResult = ({ setInfo }) => {
  
  let name = cookie.getCookie(cookie.keys.quickSearch.typeName)
  let filter = cookie.getCookie(cookie.keys.quickSearch.filterSelection)
  let value = cookie.getCookie(cookie.keys.quickSearch.filterValue)
  // console.log('filter:', filter, ' value:', value);
  let input = jsons.input(filter, value)
  // console.log(input);
  
  let location = useLocation()
  let bread = linkData('Search', getFullPath(location))
  let json3rd = JSON.stringify(bread)
  // console.log(bread, json3rd);
  cookie.setCookie(cookie.keys.protein.bread3rd, json3rd)
  const texts = {
    bread: breadData.create('Peptide', `Search ${value} by ${name} filter`, 'Search'),
    
    table: {
      head: jsons.items_text,
    }
  }
  
  const [output, setOutput] = useState({[jsons.output[0]]: 0})
  const [items, setItems] = useState([])
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  const apiResult = () => {
    const config = apiConfig.peptide.quick_search(input) //5
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);

        const output = result.data;
        setOutput(output)
        // console.log('output', output)
        
        setItems(output[spp.common.output.items])
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }

  useEffect(() => {
    if (axiosState.keepRest(state))
      apiResult()
  })

  return (
    <LayoutPageV2 bread={texts.bread}
      searchInput={[]} searchOutput={output}>
      <MuiTableContainer size='small'>
        <TableHeadRow>
          {texts.table.head.map((title, index) => (
            <StyledTableCell key={index}
              align={index === 0 ? 'right' : 'left'}>
              <TagText html={title} />
            </StyledTableCell>
          ))}
        </TableHeadRow>
        {state.isLoading ? <LoadingAnime /> :
          <TableBody className={scssTable.table_borderless}>
            {items.map((item, index) => (
              rowChildren(item, index, navigate)
            ))}
          </TableBody>}
      </MuiTableContainer>
    </LayoutPageV2>
  )
}
export default PeptideQuickSearchResult