import { useSearchParams } from "react-router-dom";
import { TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';

import scssPeptide from 'views/peptide/PeptideDFBPIDResult.module.scss';
import paths from "utils/network/apiPath";
import { breadData } from 'components/bread/BreadBarV2';
import LayoutPageV2 from "layouts/LayoutPageV2";
import cookie from "utils/cookie";
import { Code } from "components/tag/Text";
import { getRow } from 'components/table/table';
import { useEffect, useState } from "react";
import StyledTableCell from 'components/table/StyledTableCell';
import { MuiTableContainer as TableContainer } from "components/table/MuiTableV2";

//------------------------------------------------------------------------------

const texts = {
  bread:  breadData.create('Tools', 'Protein & Peptide Structure Prediction Results (PPSPred-V1.0)', 'Structure Prediction', 'Prediction Result'),
  
  table: {
    head: [
      "Sequence length",
      "Sequence",
    ],
    json: [ //unused
      "sequence_length",
      "sequence",
    ],
  },
}

const MuiTableContainer = (props) => (
  <TableContainer size='small'>
    {props.children}
  </TableContainer>
)

const MuiTableCell = (props) => (
  <StyledTableCell style={{ whiteSpace: 'pre-line' }} {...props}>
    {props.children}
  </StyledTableCell>
)

const MuiTableHeadCell1st = (props) => (
  <StyledTableCell style={{ width: 190, backgroundColor:'var(--green-alpha-alpha-16)'}} align='right'
    component="th" scope="row" {...props}>
    {props.children}
  </StyledTableCell>
)

//---------------------------------------------------------------------------
export default function ToolPPStructurePredictionResult({ setInfo }) {
  const jobSequence = cookie.getCookie(cookie.keys.tool.ppspJobSeq)
  //move to ${paths.af3}/index_af3.html
  // const jobName = cookie.getCookie(cookie.keys.tool.ppspJobName)
  // const jobFile = cookie.getCookie(cookie.keys.tool.ppspJobFile) //"fold_eyklvvvgac",
  // const cifFile = `${jobName}/${jobFile}_model_0.cif` // fold_eyklvvvgac_model_0.cif
  // console.log(jobSequence);
  // console.log(jobName);
  // console.log(jobFile);
  
  
  //只需要把這個變量對應起來即可，就是肽的序列
  // const peptideSequence = "AA";
  const peptideSequence = "";
  // console.log('peptideSequence', peptideSequence);
  
  const [output, setOutput] = useState({})
  const [rows, setRows] = useState(getRows(texts, ''))
  function getRows(texts, output) {
    return ([
      getRow(texts.table, output),
    ])
  }
  
  const rowChildren = (row, index) => {
    // console.log(row);
    let name = row.name
    let value = row.value
    // console.log(name, value);
    switch(name) {
      case "Sequence length":
        value = jobSequence.length
        break
      case "Sequence":
        value = (
          <div className={scssPeptide[getScssNameCell(name)]}>
            <Code>{jobSequence.toCode()}</Code>
          </div>)
        break
    }
    
    return (
      <TableRow key={name}>
        <MuiTableHeadCell1st>{name}</MuiTableHeadCell1st>
        <MuiTableCell>{value}</MuiTableCell>
      </TableRow>)
  }
  
  function getScssNameCell(name = '', json = '') {
    // console.log(name, json);
    if (name === "Sequence" || json === "sequence")
      return 'right_cell_code'
    return ''
  }
  
  useEffect(() => {
    setRows(getRows(texts, output))
  }, [])
  
  //------------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread}>
        <MuiTableContainer>
          <TableBody style={{border:'none'}}>
            {rows[0].map((row, index) => (
              rowChildren(row, index)
            ))}
          </TableBody>
        </MuiTableContainer>
        <div style={{height:'10px'}}></div>
        
        <div style={{
          position: 'relative',
          // width: '95vw', 
          height: '95vh',
           }}>
        <iframe
          src={`${process.env.PUBLIC_URL}${paths.af3}/index_af3.html`}
          width="100%"
          height="540px" //40px + height(molstart index.html #app)
          title="JS MolstarViewer"
          style={{ border: 'none' }}
        />
      </div>
    </LayoutPageV2>);
};
