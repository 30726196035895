import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CategoryScale } from 'chart.js';
import Chart from "chart.js/auto";

import 'assets/scss/commonV2.scss';
import 'assets/scss/temp.scss';
import scssFull from 'views/peptide/PeptideDFBPIDFull.module.scss';

import LoadingAnime from 'components/LoadingAnime';
import SnackBar, { emptyResult, snackInfo, snackType } from 'components/SnackBar';
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { spp } from 'utils/network/jsons';
import { breadData } from 'components/bread/BreadBarV2';
import LineChart from 'components/chart/LineChart';
import chartProp from 'components/chart/chartProp';
import Text from 'components/tag/Text';
import StyledTableCell from 'components/table/StyledTableCell';
import cookie from 'utils/cookie';
import { isEmptyObjOrArray } from 'utils/general';
import ChartSection from 'components/chart/ChartSection';
import paths from 'utils/network/apiPath';
import MuiTabPanel, { handleChangeTab, scrollTab } from 'components/mui/MuiTabPanel';
import { rowData } from 'components/table/table';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import { color } from 'echarts';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_pc_r?keyword=aa
// Go in: homepage -> 'Tools' tab -> 'Peptide Calculator' button -> type char in 'single letter' -> click 'search' button -> this page
// path: paths.spp.tool.peptide_search,
const jsons = spp.tool.peptide //18
const texts = {
  tabs: ['',],

  table: {
    head: jsons.output_text,
    json: jsons.output,
    letter: function () {
      return (<>NH<sub>2</sub></>) //NH2
    },
    extinction: function () {
      return (<> M<sup>-1</sup>cm<sup>-1</sup></>) //M-1cm-1
    },
    absorb: ' g/l',
    assuming: '(assuming all Cys residues are reduced)',
  },

  chart: {
    title: 'Net Charges of the peptide at Various pH Values',
    x: 'pH',
    y: 'Charges',
    label: [
      'Negative Charge',
      'Positive Charge',
      'Net Charge',
    ]
  }
}
/*
const useStyles = styled({
  tableContainer: {
    display: "block"
  },
  table: {
    display: "flex"
  },
  tableHead: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  },
  tableBody: {
    display: "flex",
    flexGrow: 1
  },
  tableRow: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1
  }
});
*/
const MuiTableHeadCell = (props) => (
  <StyledTableCell {...props}>
    {props.children}
  </StyledTableCell>
)

Chart.register(CategoryScale);
Chart.defaults.font.size = 16;
//---------------------------------------------------------------------------
const ToolPeptideCalculatorResult = ({ setInfo }) => {
  const [searchParams] = useSearchParams()
  const keyword = searchParams.get(paths.params.keyword)
  let input = jsons.input(keyword)
  // console.log(input);
  
  let bread3rd = cookie.getCookie(cookie.keys.protein.bread3rd)
  // console.log('bread3rd', bread3rd);
  const words = {
    bread: breadData.create('Tools', 'Peptide calculator data output', bread3rd, 'Result'),
  }

  //---------------------------------------------------------------------------
  const [output, setOutput] = useState({}) //for table data
  const [items, setItems] = useState([]) //for line chart data
  
  const [state, setState] = useState(axiosState.init())
  const navigate = useNavigate()
  let location = useLocation()
  const apiResult = () => {
    const config = apiConfig.tool.peptide_calculator_result(input) //18
    // console.log(input);
    // console.log(config);

    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setState(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        setOutput(data)

        let item = result.data[jsons.output_chart]
        if (isEmptyObjOrArray(item)) {
          setInfo(snackInfo.openInfo(emptyResult))
          return null;
        }
        setItems(item)

        let row = getRow(texts.table, data)
        setRows(row)
        // console.log(item);
        // console.log(row);
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //---------------------------------------------------------------------------
  const [rows, setRows] = useState(getRow(texts.table, ''))
  function getRow(table, output) {
    let rows = []
    table.head.map(function (item, index) {
      let name = table.json[index]
      let value = output[name]
      if (index === 9)
        value = output[name] + texts.table.absorb
      return (rows.push(rowData(item, value)))
    })
    return (rows)
  }

  function addSubSup(value, index) {
    if (index === 1) {
      return (<>{texts.table.letter()}{value.slice(3)}</>)
    } else if (index === 8) {
      return (<>{value}{texts.table.extinction()}</>)
    }
    return value
  }
  
  //---------------------------------------------------------------------------
  // TabPanel cell
  const TabCell = (props) => { //show table key&value
    const { tab, output, outputObj } = props
    return (
      <>
        <Text className={'Title-Audiowide28px-Regular-Title'}>{tab.title}</Text>

        {tab.head.map((item, index) => {
          // console.log(index)
          let isHide = false

          return (isHide
            ? null
            : <div key={index} className={scssFull['right_cell']}>
              <div key={`div-${item}`} className={scssFull.cell_label}>
                <Text key={`text-${item}`} className={'Contents-QuanticoBody16px-Medium-White_75'}>
                  {item}
                </Text>
              </div>
              <div key={`div-${index}`} className={scssFull.cell_value}>
                <Text key={`text-${index}`} className={'Contents-QuanticoBody16px-Medium-White'}>
                  {renderSubComponent(tab.key[index], tab, index)}
                </Text>
              </div>
            </div>)
        })}
      </>
    )
  }
  
  const renderSubComponent = (key, tab, index) => {
    const value = output[key];

    switch (key) {
      case "extinction":
      case "absorb":
        return `${value} (assuming all Cys residues are reduced)`;
      default:
        return value;
    }
  }
  
  //---------------------------------------------------------------------------
  // Chart
  const tableLabel = []
  for (const i of Array(15).keys())
    tableLabel.push(`${texts.chart.x}${i}.00`)
  // console.log(tableLabel);

  const chartDatasets = [
    chartProp.datasets.create(texts.chart.label[0], items.map((data) => data[jsons.chart_output[0]]), '#818bb8'),
    chartProp.datasets.create(texts.chart.label[1], items.map((data) => data[jsons.chart_output[1]]), '#51cda0'),
    chartProp.datasets.create(texts.chart.label[2], items.map((data) => data[jsons.chart_output[2]]), '#de7e75'),
  ]
  const chartOptions = chartProp.options.peptideCalResAxisTitleXY(texts.chart.x, texts.chart.y,true)
  const chartData = chartProp.data.carateMulti(
    '',
    '',
    // tableLabel,
    items.map((data) => data[jsons.chart_output[3]]),
    chartDatasets,
  )
  
  //---------------------------------------------------------------------------
  const tabs = {
    overview: {
      head: ["Sequence", "3-Letter amino acid", "Length", "Molecular weight", "pI", "Net charge (pH=7.0)", "GRAVY", "Hydrophilic residue ratio", "Extinction coefficients", "Absorb coefficients	"],
      title: texts.tabs[0],
      key: ["sequence", "threecode", "length", "weight", "pi", "netcharge", "gravyscore", "ratio", "extinction", "absorb"]
    },
  }
  
  /****************************
   * Single Page to Scroll - Start
   ****************************/
  // TabPanel
  const isShownAllTabs = true //false=multi tabs
  const ref = useRef([])
  const [tabValue, setTabValue] = useState(0);
  
  useEffect(() => {
    if (axiosState.keepRest(state))
      apiResult()
    // console.log(numResultError);
    // eslint-disable-next-line
  }, [])
  
  /****************************
   * Single Page to Scroll - End
   ****************************/
  
  const ContentObjList = [
    <TabCell tab={tabs.overview} output={output} />,
  ]
  
  //---------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={words.bread}>

        {state.isLoading ? <LoadingAnime /> :
          <div className={scssFull.layout_without_pt}>
            <div className={scssFull.frame_right}>
              {ContentObjList.map((item, index) => (
                <MuiTabPanel key={index} index={index} value={tabValue} tabRef={ref}
                  isShownAllTabs={isShownAllTabs}>
                  {item}
                </MuiTabPanel>
              ))}
            </div>
          </div>}
          <ChartSection
          chartBlockTitle={texts.chart.title}
          mainChart={
            <LineChart data={chartData} options={chartOptions}/>
          } />
      
    </LayoutPageV2 >
  )
}
export default ToolPeptideCalculatorResult