import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { backdropClasses, Box, Card, CardActionArea, CardMedia, Grid, IconButton, Tooltip } from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { TableHead, TableRow, tableCellClasses } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CircularProgress } from '@mui/material';

import v2 from "assets/scss/_variablesV2.scss";
import scss from './ToolPPDocking.module.scss'
import scssPP from './ToolPPStructurePrediction.module.scss'
import scssACE from './ToolACEiPP.module.scss';
import scssTable from 'components/table/TableV2.module.scss'
import { breadData } from 'components/bread/BreadBarV2';
import { images } from 'utils/imgs';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import LoadingAnime from 'components/LoadingAnime';
import { MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import Text, { TagText } from 'components/tag/Text';
import { appendFromObjectKayValue, getKeyByValue } from 'utils/object';
import { spp } from 'utils/network/jsons';
import StyledTableCell from 'components/table/StyledTableCell';
import { StyledTextFieldStandardDark } from 'components/tool/StyledTextField';
import GreenButton from 'components/button/GreenButton';
import MuiLoadingButton from 'components/mui/MuiLoadingButton';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { checkInvalidSeq, isFASTAformat } from 'utils/general';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTextField,{StyledTextFieldDisable}  from 'components/tool/StyledTextField';
import { files } from 'utils/files';
import { getListCellAlign, getListCellStyle, getStateIcon, MuiTableCell4Head, TableCell4HeadTop } from './ToolPPStructurePrediction';
import { texts as textsPP } from './ToolPPStructurePrediction';
import { fakeJobListItems } from './ToolPPdata';
import MuiTooltip from 'components/mui/MuiTooltip';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_ppd

const texts = {
  // step:
  // title:
  // manual:
  // upload:
  ...textsPP,
  
  bread:  breadData.create('Tools', 'Protein-Peptide Docking', 'Docking'),
  
  manual: {
    ...textsPP.manual,
    step: '1.Please enter protein and peptide sequence in FASTA format. Protein and peptide sequence are separated by a symbol (:).\n2.Peptide sequence length should be between 2 and 8192.\n3.Please enter less than 5 tasks for each prediction.',
  },
  
  upload: {
    ...textsPP.upload,
    step: '1.Please upload a *.fasta (or *.txt) file. Protein and peptide sequence are separated by a symbol (:).\n2.Protein sequence length should be between 2 and 8192.\n3.Please enter less than 100 tasks for each prediction.',
  },
  
  exampleText: `>Docking01
SVNEVPDYHEDIHTYLREMEVKCKPKVGYMKKQPDITNSMRAILVDWLVEVGEEYKLQNETLHLAVNYIDRFLSSMSVLRGKLQLVGTAAMLLASKFEEIYPPEVAEFVYITDDTYTKKQVLRMEHLVLKVLAFDLAAPTINQFLTQYFLHQQPANCKVESLAMFLGELSLIDADPYLKYLPSVIAAAAFHLALYTVTGQSWPESLVQKTGYTLETLKPCLLDLHQTYLRAPQHAQQSIREKYKNSKYHGVSLLNPPETLNVHHHHHHH:IHPF
>Docking02
GAGVSEYELPEDPRWELPRDRLVLGKPLGEGAFGQVVLAEAIGLDKDKPNRVTKVAVKMLKSDATEKDLSDLISEMEMMKMIGKHKNIINLLGACTQDGPLYVIVEYASKGNLREYLQARRPPGLEYSYNPSHNPEEQLSSKDLVSCAYQVARGMEYLASKKCIHRDLAARNVLVTEDNVMKIADFGLARDIHHIDYYKKTTNGRLPVKWMAPEALFDRIYTHQSDVWSFGVLLWEIFTLGGSPYPGVPVEELFKLLKEGHRMDKPSNCTNELYMMMRDCWHAVPSQRPTFKQLVEDLDRIVALTSNQE:IHPF
>Docking03
MKHHHHHHHDEVDGMTEYKLVVVGACGVGKSALTIQLIQNHFVDEYDPTIEDSYRKQVVIDGETSLLDILDTAGQEEYSAMRDQYMRTGEGFLLVFAINNTKSFEDIHHYREQIKRVKDSEDVPMVLVGNKSDLPSRTVDTKQAQDLARSYGIPFIETSAKTRQGVDDAFYTLVREIRKHKEK:IH`,
}
const jsonsList = texts.jsonsList

//---------------------------------------------------------------------------
export default function ToolPPDocking({ setInfo }) {
  let manualInit = cookie.getCookie(cookie.keys.tool.ppdManualInput)
  
  //Manual input v---------------------------------------------------------------------------
  const jsonsSubmit = spp.tool.ppsp_ppd_submit
  const [manualInput, setManualInput] = useState(manualInit)
  const [isInputError, setInputError] = useState(false)
  const [manualInputErrMsg, setManualInputErrMsg] = useState("")
  const handleChangeManual = (value) => {
    // console.log('value', value);
    
    setManualInput(value)
    setInputError(false)
  }
  
  const handleClickManualReset = () => {
    setManualInput('')
    cleanPPDState()
  }
  function cleanPPDState() {
    cookie.setCookie(cookie.keys.tool.ppdManualInput, '')
  }
  
  // Submit
  const handleClickManualSubmit = () => {
    const objInvalidChar = checkInvalidSeq(manualInput);
    const objFASTAformat = isFASTAformat(manualInput, ':');
    
    if (objInvalidChar.isInvalid) {
      setInputError(true)
      setManualInputErrMsg(objInvalidChar.errMsg)
      setInfo(snackInfo.openError(objInvalidChar.errMsg))
    } else if (objFASTAformat.isInvalid) {
      setInputError(true)
      setManualInputErrMsg(objFASTAformat.errMsg)
      setInfo(snackInfo.openError(objFASTAformat.errMsg))
    } else {
      setInputError(false)
      setManualInputErrMsg("")
      
    if (manualInput !== '') {
      cookie.setCookie(cookie.keys.tool.ppspManualInput, manualInput)
      apiSubmit()
    } else {
      setInputError(true)
      }
    }
  }
  
  // Example
  const handleClickExample = () => {
    setManualInput(texts.exampleText)
  }
  
  const navigate = useNavigate()
  let location = useLocation()
  const apiSubmit = () => {
    const input = jsonsSubmit.input_docking(manualInput)
    const config = apiConfig.tool.ppsp_ppd_submit(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTable(axiosState.error(false, stateTable.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTable(axiosState.resultCode200())
        // console.log(result.data);
        
        let data = result.data
        let code = data.result_code
        console.log('result_code', code);
      }
    }).catch(err => {
      setStateTable(axiosState.error(axiosCatch.isTimeout(err), stateTable.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  //Manual input ^------------------------------------------------------------------------------
  
  //Upload file v------------------------------------------------------------------------------
  const jsonsUpload = spp.tool.ppsp_ppd_upload
  const fileInputRef = useRef(null);
  const handleFileSelect = () => { //Browse
    fileInputRef.current.click();
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      setFileName(file.name);
    }
  };
  
  const handleClickFileReset = () => { // Reset
    setFile(null)
    setFileName('');
  }
  
  const handleClickFileUpload = () => {
    if (file === null) {
      setInfo(snackInfo.openError("Please select a *.fasta (or *.txt) file"))
    } else {
      apiFileUpload()
    }
  }
  
  //------------------------------------------------------------------------------
  const [stateList, setStateList] = useState(axiosState.set(false, false, false, 0))
  const apiFileUpload = async () => {
    setStateList(axiosState.loadingState())
    const formData = new FormData();
    formData.append('file', file);
    // formData.append('job_type', jsonsUpload.job_type.docking)
    appendFromObjectKayValue(formData, jsonsUpload.form_input_docking())

    const config = apiConfig.tool.ppsp_ppd_upload(formData)
    // console.log('config', config);
    axios(config).then((result) => {
        // console.log('result', result);
      if (result.data.result_code !== 200) {
        setStateList(axiosState.error(false, stateList.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateList(axiosState.resultCode200())
        
        let data = result.data
        // console.log(data)
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  };
  //Upload file ^------------------------------------------------------------------------------
  
  //result table v------------------------------------------------------------------------------
  let itemsFake = JSON.parse(`[${fakeJobListItems}]`);
  // console.log(itemsFake);
  // const [tableItems, setTableItems] = useState(itemsFake) //debug
  // const [stateTable, setStateTable] = useState(axiosState.resultCode200()) //debug
  
  const [tableItems, setTableItems] = useState([])
  const [stateTable, setStateTable] = useState(axiosState.init())
  const apiTableList = () => {
    setStateTable(axiosState.loadingState())
    const input = jsonsList.input_docking()
    const config = apiConfig.tool.ppsp_ppd_job_list(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      if (result.data.result_code !== 200) {
        setStateTable(axiosState.error(false, stateTable.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateTable(axiosState.resultCode200())
        // console.log(result.data);
        let data = result.data
        // console.log(data)
        
        let items = data[spp.common.output.items]
        if(items !== undefined)
          setTableItems(items)
        // console.log('items', items);
      }
    }).catch(err => {
      setStateTable(axiosState.error(axiosCatch.isTimeout(err), stateTable.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  }
  
  const TableBodyCell = (props) => {
    const { tableBodyCell, children, index } = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    let isError = (children['status'] === jsonsList.status.ng)
    let value, text, align, rowSpan = 1, name
    return (
      tableBodyCell.map((cell, indexX) => {
        value = children[cell]
        text = value
        if(text === undefined)
          text = '-'
        // console.log('cell', cell, 'value', value);
        
        align = getListCellAlign(indexX, null, cell)
        if(cell === 'status' ) {
          text = getStateIcon(value)
        }
        if(cell === 'job_input' && Object.isObject(value)) {
          let arrSeqs = value['sequences']
          let objPro = arrSeqs[0]
          text = objPro.protein.sequence
          // console.log(cell, objPro);
          
          if(isError) {
            let textError = children['error_message']
            text = <MuiTooltip title={textError}>
              {text}
            </MuiTooltip>
          }
        }
        
        return (
          <MuiTableCell key={index+''+indexX+''+cell} align={align} rowSpan={rowSpan}
            style = {{paddingTop: '2px', paddingBottom: '2px', height: '32px'}}>
            {text}
          </MuiTableCell>)
      }))
  }
  
  const rowChildren = (item, index) => {
    // console.log(item);
    // console.log(item.status);
    return (
      <TableRow2Body key={index} className={scssTable.body_row_interactive}
        onClick={() => onClickList(item)}
        style={{ cursor: item.status === jsonsList.status.ok ? "pointer" : "auto" }}>
        <TableBodyCell key={index} index={index}
          tableBodyCell={texts.tableBodyCell}>
          {item}
        </TableBodyCell>
      </TableRow2Body>)
  }
  
  function onClickList(item) {
    if(item.status === jsonsList.status.ok) {
      let value = item['job_input']
      let arrSeqs = value ? value['sequences'] : []
      let objPro = arrSeqs ? arrSeqs[0] : {}
      let seq = objPro ? objPro.protein.sequence : ''
      let job = item['job_name']
      let jobName = job ? job : ''
      let file = item['file_name']
      let fileName = file ? file : ''
      cookie.setCookie(cookie.keys.tool.ppspJobSeq, seq)
      cookie.setCookie(cookie.keys.tool.ppspJobName, jobName)
      cookie.setCookie(cookie.keys.tool.ppspJobFile, fileName)      
      // console.log('text', text);
      // console.log('jobName', jobName);
      // console.log('fileName', fileName);
      
      navigate(paths.spp.tool.pp_structure_prediction_result())
    }
  }
 
  const thirdGrid = 5;
  //------------------------------------------------------------------------------
  useEffect(() => {
    apiTableList()
  }, [])
  
	const tableCol = texts.tableHeadCell.length
  //------------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread}>
      <div className={scssPP.layout}>
        
        {/* Manual input */}
        <ToolSingleBlock stepText={texts.step[0]} title={texts.title[0]}
          subtitleBlock = {
            <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>{texts.manual.step}
            </Text>
          }
          mainBlockContent={
            <Grid container spacing={2}>
              <div className={scssPP.manual_rows}>
                <div className={scssPP.button_bar}>
                  <Text className={'Contents-QuanticoBody16px-SemiBold-GreenAlpha_75 link'}
                    onClick={handleClickExample}>
                    {texts.manual.example}</Text>
                </div>
                
                <StyledTextFieldDisable maxRows={8}
                  className={scssACE.example_box}
                  value={texts.exampleText}
                  disabled
                />
                
                <StyledTextField
                  fullWidth
                  maxRows={10}
                  className={scssPP.input_box}
                  placeholder={texts.manual.searchHint}
                  label={texts.manual.searchHint}
                  required
                  rows={10}
                  value={manualInput}
                  onChange={(event) => { handleChangeManual(event.target.value) }}
                  error={isInputError}
                  helperText={manualInputErrMsg}
                />
                <div className={scssPP.button_bar}>
                  <GreenButton onClick={handleClickManualSubmit} >
                    Submit</GreenButton>
                  <GreenButton onClick={handleClickManualReset}>
                    Reset</GreenButton>
                </div>
              </div>
            </Grid>
          }>
        </ToolSingleBlock>
        
        {/* Upload file */}
        <ToolSingleBlock stepText={texts.step[1]} title={texts.title[1]}
          subtitleBlock={
            <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>
              {texts.upload.step}
            </Text>
          }
          mainBlockContent={
          <Grid container spacing={2}>
            <div className={scssPP.manual_rows}>
              <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>
                <a href={files.root[texts.upload.fileName]} download={texts.upload.fileName}
                  className={'Contents-QuanticoBody16px-SemiBold-GreenAlpha_75 link'}>
                  {texts.upload.file}</a>
              </Text>
              <StyledTextFieldDisable maxRows={8}
                className={scssACE.example_box}
                value={texts.exampleText}
                disabled
              />
                      
                {/* Select a *.fasta (or *.txt) file: */}
                <Grid container spacing={1} direction="row" alignItems="center" style={{gap: '8px'}}>
                    <Grid item>
                      <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>{texts.upload.select}</Text>
                    </Grid>
                </Grid>
                
                {/* Browse */}
                <Grid container spacing={1} direction="row" alignItems="center" style={{gap: '8px'}}>
                  <Grid item>
                    <input accept=".fasta, .txt" type="file"
                      hidden
                      className="custom-file-input" data-target="file-uploader" id="file-uploader"
                      ref={fileInputRef}
                      onChange={handleChangeFile}
                    />
                  <MuiLoadingButton
                    onClick={handleFileSelect}
                    disabled={stateList.isLoading}>
                    Browse</MuiLoadingButton>
                  </Grid>
                  <Grid>
                    {fileName !== "" && <span className={'Contents-QuanticoBody16px-SemiBold-GreenAlpha_75'}
                      style={{whiteSpace: 'break-word', wordBreak: 'break-all'}}>
                      {fileName}</span>}
                  </Grid>
                </Grid>
                
                {/* button */}
                <Grid container spacing={1} direction="row" alignItems="center">
                  <Grid item s={thirdGrid}>
                    <div className={scssPP.button_bar}>
                      <MuiLoadingButton
                        onClick={handleClickFileUpload}
                        disabled={stateList.isLoading || file === null}
                        loading={stateList.isLoading}>
                          Upload</MuiLoadingButton>

                      <GreenButton onClick={handleClickFileReset}>
                        Reset</GreenButton>
                    </div>
                  </Grid>
                </Grid>
            </div>
          </Grid>
        }>
        </ToolSingleBlock>
      
      
      <MuiTableContainer>
          <TableHeadRow> {/* title */}
            {texts.tableHeadCell.map((title, index) => (
              <MuiTableCell4Head key={index} index={index}
                align={getListCellAlign(index, title)}
                style={getListCellStyle(index, title)}>
                {title}</MuiTableCell4Head>
            ))}
          </TableHeadRow>
          <TableBody>{/* value */}
            {stateTable.isLoading
            ? <MuiTableCell colSpan={tableCol}>
                <LoadingAnime />
              </MuiTableCell>
            : <>
                {tableItems.length === 0
                ? <TableRow2Body>
                    <TableBodyCell tableBodyCell={texts.tableBodyCell}>-</TableBodyCell>
                  </TableRow2Body>
                : tableItems.map((item, index) => {
                  return rowChildren(item, index)
                })}
              </>}
          </TableBody>
        </MuiTableContainer>
        
      </div>
    </LayoutPageV2>
  )
}
